.photo-content-wb {
	$this: &;

	&__main-top-title {
		@extend %o-title-avenir-heavy-16;
		color: #4a4844;
		line-height: 1.2;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}

	&__main-title {
		@extend %o-title-sora-extrabold-35;
		color: $color-black;
		line-height: 1.2;
		margin: 0 auto 5rem;
		text-transform: uppercase;
		width: 55%;

		@include breakpoint(1024px down) {
			width: 75%;
		}

		@include breakpoint(mediumLow down) {
			width: 100%;
		}
	}

	&__item {
		margin-bottom: 7.5rem;

		@include breakpoint(mediumLow down) {
			margin-bottom: 5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__content {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		padding-left: 3rem;

		@include breakpoint(mediumLow down) {
			margin-top: 3rem;
			padding-left: 0;
		}

		.flex-dir-row-reverse & {
			padding-left: 0;
			padding-right: 3rem;

			@include breakpoint(mediumLow down) {
				padding-right: 0;
			}
		}
	}

	&__title-box {
		display: flex;
		flex-flow: row wrap;
		background-color: #f7f8f3;
	}

	&__top-title {
		@extend %o-title-avenir-book-24;
		flex: 0 1 auto;
		display: flex;
		align-items: center;
		color: $color-black;
		padding: 1rem 3rem;
		margin-bottom: 0;
		width: 100%;

		.blue & {
			color: $color-black;
		}

		@include breakpoint(mediumLow down) {
			font-size: 1.6rem;
			justify-content: center;
		}
	}

	&__title {
		@extend %o-title-sora-extrabold-24;
		flex: 0 1 auto;
		display: flex;
		align-items: center;
		color: #fff;
		text-transform: uppercase;
		background-color: #f7444e;
		padding: 1rem 3rem;
		border-bottom: 0.5rem solid $color-black;
		margin-bottom: 0;
		width: 100%;

		.blue & {
			background-color: $color-black;
			border-bottom-color: #78bcc4;
		}

		.cyan & {
			color: $color-black;
			background-color: #78bcc4;
			border-bottom-color: $color-black;
		}

		@include breakpoint(mediumLow down) {
			border-bottom: 0;
			font-size: 2.4rem;
			justify-content: center;
		}
	}

	&__text {
		p {
			color: #3c3c3c;
		}
	}

	&__links {
		flex: 0 1 auto;
		align-self: flex-end;
		margin-top: 1.5rem;
		width: 100%;

		.flex-dir-row-reverse & {
			text-align: right;

			@include breakpoint(mediumLow down) {
				text-align: left;
			}
		}

		ul {
			padding: 0;

			li {
				display: inline-block;
				margin-right: 2.5rem;

				@include breakpoint(mediumLow down) {
					display: block;
					margin-bottom: 1rem;
					margin-right: 0;
				}

				&:last-child {
					margin-right: 0;

					@include breakpoint(mediumLow down) {
						margin-bottom: 0;
					}
				}

				a {
					background-color: transparent;
					border: transparent;
					color: #052239;
					display: inline-block;
					font-family: $font-poppins-Bold;
					font-size: 1.6rem;
					line-height: 1.2;
					letter-spacing: 0.2rem;
					padding: 0;
					text-transform: none;

					@include transition(all 0.4s ease);

					&:hover {
						background-color: transparent;
						color: #c01823;
					}

					color: $color-black;
					line-height: 1;
				}
			}
		}
	}
}
