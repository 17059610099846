.unique-tl {
	$this: &;

	position: relative;

	&::before {
		background-color: $color-green;
		display: block;
		content: "";
		position: absolute;

		@include breakpoint(768px up) {
			width: 100%;
			height: 1px;
			margin-top: -1px;
			position: absolute;
			top: 50%;
			left: 0;
		}

		@include breakpoint(767px down) {
			width: 1px;
			height: 100%;
			margin-left: -1px;
			position: absolute;
			top: 0;
			left: 50%;
		}
	}

	&__cell {
		display: flex;

		@include breakpoint(1700px up) {
			justify-content: center;
		}

		@include breakpoint(768px up) {
			flex-flow: row nowrap;
			padding-top: 12rem;
			padding-bottom: 12rem;
			overflow-x: auto;
		}

		@include breakpoint(767px down) {
			flex-flow: row wrap;
			padding-bottom: 3rem;
		}
	}

	&__spacer {
		@include breakpoint(768px up) {
			min-width: 120px;

			// temporary fix?
			// might add it as an option in the future?
			&:first-child {
				min-width: 60px;
			}

			// temporary fix?
			// might add it as an option in the future?
			&:last-child {
				min-width: 180px;
			}
		}

		@include breakpoint(767px down) {
			width: 100%;
			height: 100px;
		}
	}

	&__content {
		@include breakpoint(767px down) {
			width: 100%;
		}
	}

	&__content-container {
		position: relative;
	}

	&__circle {
		background-color: #fefefe;
		border: 1px solid $color-green;
		border-radius: 50%;
		width: 73px;
		height: 73px;
		position: relative;
		z-index: 2;
	}

	&__circle-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img {
			width: 36px;
		}
	}

	&__circle-year {
		@extend %o-title-poppins-bold-18;
		color: $color-green;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__year {
		@extend %o-title-josefin-sans-bold-24;
		text-align: center;
		position: absolute;

		@include breakpoint(768px up) {
			width: 100%;

			&.top {
				margin-bottom: 4px;
				bottom: 100%;
			}

			&.bottom {
				margin-top: 4px;
				top: 100%;
			}
		}

		@include breakpoint(767px down) {
			top: 50%;
			transform: translateY(-50%);

			&.left {
				margin-right: 10px;
				right: 100%;
			}

			&.right {
				margin-left: 10px;
				left: 100%;
			}
		}
	}

	&__line {
		background-color: $color-green;
		position: absolute;
		z-index: 1;

		&::before,
		&::after {
			background-color: $color-green;
			display: block;
			content: "";
			position: absolute;
		}

		&::before {
			border-radius: 50%;
			width: 9px;
			height: 9px;
		}

		@include breakpoint(768px up) {
			height: 100px;
			left: 50%;

			&::before {
				left: -4px;
			}

			&::after {
				width: 1px;
				height: 100%;
				left: 0;
			}

			&.top {
				bottom: 100%;

				&::before {
					top: -4px;
				}

				&::after {
					top: 0;
				}

				#{$this}__text {
					top: -9px;
				}
			}

			&.bottom {
				top: 100%;

				&::before {
					bottom: -4px;
				}

				&::after {
					bottom: 0;
				}

				#{$this}__text {
					bottom: -9px;
				}
			}
		}

		@include breakpoint(767px down) {
			width: 100px;
			top: 50%;

			&::before {
				top: -4px;
			}

			&::after {
				width: 100%;
				height: 1px;
				top: 0;
			}

			&.left {
				right: 100%;

				&::before {
					left: -4px;
				}

				#{$this}__text {
					left: -50px;
				}
			}

			&.right {
				left: 100%;

				&::before {
					right: -4px;
				}

				#{$this}__text {
					left: 0;
				}
			}
		}

		@include breakpoint(479px down) {
			&.left {
				#{$this}__text {
					left: 0;
				}
			}
		}
	}

	&__text {
		position: absolute;

		@include breakpoint(768px up) {
			width: 150px;
			left: 15px;
		}

		@include breakpoint(767px down) {
			width: 140px;
			top: 40px;
		}

		@include breakpoint(479px down) {
			width: 130px;
			top: 30px;
		}

		ul {
			li {
				@extend %o-text-poppins-regular-12;
				padding-left: 12px;
				margin-bottom: 0;
				position: relative;

				&::before {
					background-color: $color-black;
					border-radius: 50%;
					display: block;
					content: "";
					width: 4px;
					height: 4px;
					position: absolute;
					top: 6px;
					left: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
