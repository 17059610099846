.testimonial-s {
	$this: &;

	&__cell {
		@extend %background-image;
		border-radius: 8px;
		padding: 8rem 5%;

		@include breakpoint(1023px down) {
			padding: 5rem 10%;
		}
	}

	&__content {
		max-width: 850px;
		margin: 0 auto;
	}

	&__sym {
		margin-bottom: 1.5rem;
	}

	&__text {
		margin-bottom: 2rem;

		p {
			@extend %o-title-josefin-sans-bold-36;
			color: #fff;
			line-height: 1;
		}
	}

	&__name {
		margin-top: 2rem;

		p {
			@extend %o-text-poppins-regular-18;
			color: #fff;
			line-height: 1.5;

			strong {
				@extend %o-text-poppins-bold-18;
			}
		}
	}
}
