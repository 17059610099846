/*----------------------------------------------------------------------------------------------------------------
								ANIMATIONS
----------------------------------------------------------------------------------------------------------------*/

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-o-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	-moz-animation-name: fadeOut;
	-o-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes justUp {
	0% {
		-webkit-transform: translateY(20px);
	}
	100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes justUp {
	0% {
		-moz-transform: translateY(20px);
	}
	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes justUp {
	0% {
		-o-transform: translateY(20px);
	}
	100% {
		-o-transform: translateY(0);
	}
}

@keyframes justUp {
	0% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0);
	}
}

.justUp {
	-webkit-animation-name: justUp;
	-moz-animation-name: justUp;
	-o-animation-name: justUp;
	animation-name: justUp;
}

@-webkit-keyframes DropDown {
	0% {
		-webkit-transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes DropDown {
	0% {
		-moz-transform: translateY(-20px);
	}
	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes DropDown {
	0% {
		-o-transform: translateY(-20px);
	}
	100% {
		-o-transform: translateY(0);
	}
}

@keyframes DropDown {
	0% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0);
	}
}

@-webkit-keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0.7);
	}

	70% {
		box-shadow: 0 0 0 1.5rem rgba(250, 199, 72, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0);
	}
}

@-moz-keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0.7);
	}

	70% {
		box-shadow: 0 0 0 1.5rem rgba(250, 199, 72, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0);
	}
}

@-o-keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0.7);
	}

	70% {
		box-shadow: 0 0 0 1.5rem rgba(250, 199, 72, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0.7);
	}

	70% {
		box-shadow: 0 0 0 1.5rem rgba(250, 199, 72, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(250, 199, 72, 0);
	}
}

.has-fade-in {
	opacity: 0;
	position: relative;
	@include transition(all 1.5s ease);
}

.fade-in {
	opacity: 1;
}

.has-slide-right {
	position: relative;
	left: -20px;
	@include transition(all 1.5s ease);
}

.slide-right {
	left: 0;
}

.has-slide-left {
	position: relative;
	@include transition(all 1.5s ease);
	right: -20px;
}

.slide-left {
	right: 0;
}

.has-slide-top {
	position: relative;
	@include transition(all 1.5s ease);
	bottom: -20px;
}

.slide-top {
	bottom: 0;
}

.has-slide-bottom {
	position: relative;
	top: -20px;
	@include transition(all 1.5s ease);
}

.slide-bottom {
	top: 0;
}

.anim-perchar-fade {
	opacity: 0;

	&.anim-active {
		opacity: 1;
	}

	span {
		opacity: 0;

		&.fadenow {
			opacity: 1;
			@include transition(opacity 1s ease);
		}
	}
}

.transition-delay-100 {
	@include transition-delay(0.1s);
}

.transition-delay-200 {
	@include transition-delay(0.2s);
}

.transition-delay-300 {
	@include transition-delay(0.3s);
}

.transition-delay-400 {
	@include transition-delay(0.4s);
}

.transition-delay-500 {
	@include transition-delay(0.5s);
}

.transition-delay-600 {
	@include transition-delay(0.6s);
}

.transition-delay-700 {
	@include transition-delay(0.7s);
}

.transition-delay-800 {
	@include transition-delay(0.8s);
}

.transition-delay-900 {
	@include transition-delay(0.9s);
}

.transition-delay-1000 {
	@include transition-delay(1s);
}

.transition-delay-1100 {
	@include transition-delay(1.1s);
}

.transition-delay-1200 {
	@include transition-delay(1.2s);
}

.transition-delay-1300 {
	@include transition-delay(1.3s);
}

.transition-delay-1400 {
	@include transition-delay(1.4s);
}

.transition-delay-1500 {
	@include transition-delay(1.5s);
}

.transition-delay-1600 {
	@include transition-delay(1.6s);
}

.transition-delay-1700 {
	@include transition-delay(1.7s);
}

.transition-delay-1800 {
	@include transition-delay(1.8s);
}

.transition-delay-1900 {
	@include transition-delay(1.9s);
}

.transition-delay-2000 {
	@include transition-delay(2s);
}

.transition-delay-2200 {
	@include transition-delay(2.2s);
}

.transition-delay-2400 {
	@include transition-delay(2.4s);
}

.transition-delay-2600 {
	@include transition-delay(2.6s);
}

.transition-delay-3000 {
	@include transition-delay(3s);
}

.anim-fadein,
.anim-lefttoright,
.anim-righttoleft {
	-moz-transition: all 1s ease-in-out;
	-webkit-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.anim-fadein {
	opacity: 0;
	&.anim-active {
		opacity: 1;
	}
}

.anim-lefttoright {
	position: relative;
	right: 60px;
	&.anim-active {
		right: 0;
	}
}

.anim-righttoleft {
	position: relative;
	left: 60px;
	&.anim-active {
		left: 0;
	}
}

@keyframes the-end {
	0% {
		stroke-dashoffset: 5000;
		stroke-width: 5;
	}
	100% {
		stroke-dashoffset: 0;
		stroke-width: 10;
	}
}
