.staff-t {
	$this: &;
	$gap: 1.5rem;
	$gap_m: 0;

	padding-top: 8.5rem;
	position: relative;

	@include breakpoint(767px down) {
		padding-top: 4.25rem;
	}

	&__bg {
		width: 100%;
		position: absolute;
		left: 0;
		z-index: 1;

		img {
			width: 100%;
		}

		&.top {
			top: 0;
		}

		&.bottom {
			bottom: 0;
		}
	}

	&__grid {
		position: relative;
		z-index: 2;
	}

	&__container {
		position: relative;

		@include breakpoint(1023px down) {
			padding-top: 0;
		}
	}

	&__container-bg {
		@extend %fill-container;
		box-sizing: content-box;
		padding-left: 7rem;
		padding-right: 7rem;
		left: -7rem;
		z-index: 1;

		@include breakpoint(1023px down) {
			padding-left: 5rem;
			padding-right: 5rem;
			left: -5rem;
		}

		@include breakpoint(mediumLow down) {
			display: none;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 0;
		position: relative;
		z-index: 2;

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
		}

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		@include breakpoint(mediumLow down) {
			padding-left: 3rem;
			padding-right: 3rem;
			margin-bottom: 3rem;
		}
	}

	&__text {
		margin-top: 2rem;

		p {
			@extend %o-text-poppins-light-18;
			color: $color-dark-gray-3;
			line-height: 1.6;
			text-align: center;
		}
	}

	&__list {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};
		margin-top: 7rem;
		position: relative;
		z-index: 2;

		@include breakpoint(mediumLow down) {
			display: none;
		}
	}

	&__item {
		width: 25%;
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};
		margin-bottom: 5rem;

		&.cursor-auto {
			.o-card-st {
				cursor: auto;
			}
		}

		@include breakpoint(1023px down) {
			width: 50%;
		}

		.o-card-st {
			cursor: pointer;

			&__name {
				font-size: 2.4rem;
				margin-bottom: 1.5rem;
			}

			&__pos {
				p {
					@extend %o-title-poppins-light-16;
					margin-bottom: 1.5rem;
					color: $color-black;
				}
			}
		}
	}

	&__slider {
		padding-left: 3rem;
		padding-right: 3rem;
		position: relative;

		@include breakpoint(768px up) {
			display: none;
		}
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		margin-top: -8rem;
	}
}
