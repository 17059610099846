.quote-d {
	$this: &;

	background-image: url("../../assets/images/bg-quote.svg");
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 6rem;

	&__cell {
		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		margin-bottom: 1.5rem;

		svg {
			margin-bottom: 1.5rem;
		}

		p {
			@extend %o-text-josefin-sans-bold-38;
			color: $color-black;
			line-height: 1.2;
		}
	}

	&__name {
		@extend %o-text-poppins-bold-16;
		color: $color-black;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}

	&__position {
		@extend %o-text-poppins-regular-12;
		color: $color-black;
	}
}

.quote-p {
	$this: &;
	position: relative;

	&__pattern {
		left: -1rem;
		position: absolute;
		top: 0;

		@include breakpoint(1024px down) {
			display: none;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-28;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 0;
		padding-left: 15%;
		position: relative;
		text-align: right;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		&.max-width-950 {
			margin-left: auto;
			max-width: 95rem;
		}

		svg {
			left: 0;
			position: absolute;
			top: -2rem;

			@include breakpoint(767px down) {
				left: 0;
				top: -3rem;
				width: 3rem;
			}
		}
	}

	&__quote {
		margin-top: 1rem;
		position: relative;
		text-align: right;

		&.max-width-785 {
			margin-left: auto;
			max-width: 78.5rem;
		}

		p {
			font-size: 1.6rem;
		}
	}

	&__content {
		margin-top: 7rem;

		p {
			font-size: 1.8rem;
		}
	}
}
