.intro-content-d {
	$this: &;

	&.privacy-policy {
		#{$this}__text {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $color-black;
				font-family: $font-poppins-Bold;
				margin-bottom: 0;
			}

			p {
				color: $color-black;
				line-height: 2;
				margin-bottom: 0;
			}

			ul,
			ol {
				margin-bottom: 0;
				padding-left: 2.5rem;

				li {
					color: $color-black;
					margin-bottom: 0;
					line-height: 2;
				}
			}

			ul {
				li {
					&:before {
						top: 1rem;
					}
				}
			}

			ol {
				padding-left: 3rem;
			}
		}
	}

	&__pretitle {
		@extend %o-title-poppins-bold-16;
		padding-bottom: 1.6rem;
		margin-bottom: 1.5rem;
		position: relative;
		text-transform: uppercase;

		&::before {
			background: $color-black;
			display: block;
			content: "";
			width: 5.6rem;
			height: 1px;
			margin-left: -2.8rem;
			position: absolute;
			bottom: 0;
			left: 50%;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.1;
		margin-top: 0;
		margin-bottom: 0;

		&.font-size-65 {
			@extend %o-title-josefin-sans-bold-60;

			@include breakpoint(767px down) {
				font-size: 2.5rem;
			}
		}

		&.font-size-54 {
			@extend %o-title-josefin-sans-bold-54;

			@include breakpoint(767px down) {
				font-size: 2.5rem;
			}
		}

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		margin: 1.5rem auto 0;

		&.font-size-16 {
			p {
				font-size: 1.6rem;
			}
		}

		&.font-size-18 {
			p {
				font-size: 1.8rem;
			}
		}

		&.max-width-880 {
			max-width: 88rem;
		}

		p {
			color: $color-dark-gray-2;
			line-height: 1.6;
		}

		.button-box {
			margin-top: 4rem;
		}
	}

	&__pattern {
		margin-bottom: -4rem;
		text-align: right;

		@include breakpoint(1023px down) {
			display: none;
		}
	}
}
