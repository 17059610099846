%o-title-sora-extrabold-17 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.7rem;
	font-weight: 800;
}

%o-title-sora-extrabold-18 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.8rem;
	font-weight: 800;
}

%o-title-sora-extrabold-24 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2.4rem;
	font-weight: 800;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-sora-extrabold-35 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.5rem;
	font-weight: 800;

	@include breakpoint(mediumLow down) {
		font-size: 2.4rem;
	}
}

%o-title-sora-extrabold-36 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.6rem;
	font-weight: 800;

	@include breakpoint(mediumLow down) {
		font-size: 2.4rem;
	}
}

%o-title-sora-extrabold-54 {
	font-family: $font-josefin-sans-Bold;
	font-size: 5.4rem;
	font-weight: 800;
}

%o-title-sora-bold-16 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-sora-bold-24 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2.4rem;
	font-weight: 700;
}

%o-title-sora-bold-35 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.5rem;
	font-weight: 700;

	@include breakpoint(mediumLow down) {
		font-size: 2.4rem;
	}
}

%o-title-sora-bold-36 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.6rem;
	font-weight: 700;

	@include breakpoint(mediumLow down) {
		font-size: 2.4rem;
	}
}

%o-title-avenir-medium-15 {
	font-family: $font-poppins-SemiBold;
	font-size: 1.5rem;
	font-weight: 600;
}

%o-title-avenir-medium-16 {
	font-family: $font-poppins-SemiBold;
	font-size: 1.6rem;
	font-weight: 600;
}

%o-title-avenir-heavy-12 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.2rem;
	font-weight: 800;
}

%o-title-avenir-heavy-14 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.4rem;
	font-weight: 800;
}

%o-title-avenir-heavy-16 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.6rem;
	font-weight: 800;
}

%o-title-avenir-heavy-24 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2.4rem;
	font-weight: 800;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-avenir-roman-12 {
	font-family: $font-poppins-Regular;
	font-size: 1.2rem;
	font-weight: 400;
}

%o-title-avenir-roman-14 {
	font-family: $font-poppins-Regular;
	font-size: 1.4rem;
	font-weight: 400;
}

%o-title-avenir-roman-16 {
	font-family: $font-poppins-Regular;
	font-size: 1.6rem;
	font-weight: 400;
}

%o-title-avenir-book-24 {
	font-family: $font-poppins-Regular;
	font-size: 2.4rem;
	font-weight: 300;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-lato-black-24 {
	font-family: $font-poppins-Regular;
	font-size: 2.4rem;
	font-weight: 900;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-josefin-sans-bold-75 {
	font-family: $font-josefin-sans-Bold;
	font-size: 7.5rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-65 {
	font-family: $font-josefin-sans-Bold;
	font-size: 6.5rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-64 {
	font-family: $font-josefin-sans-Bold;
	font-size: 6.4rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-60 {
	font-family: $font-josefin-sans-Bold;
	font-size: 6rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-54 {
	font-family: $font-josefin-sans-Bold;
	font-size: 5.4rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-52 {
	font-family: $font-josefin-sans-Bold;
	font-size: 5.2rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-42 {
	font-family: $font-josefin-sans-Bold;
	font-size: 4.2rem;
	font-weight: 700;

	@include breakpoint(1024px down) {
		font-size: 3.6rem;
	}

	@include breakpoint(mediumLow down) {
		font-size: 3rem;
	}
}

%o-title-josefin-sans-bold-40 {
	font-family: $font-josefin-sans-Bold;
	font-size: 4rem;
	font-weight: 700;

	@include breakpoint(1024px down) {
		font-size: 3.6rem;
	}

	@include breakpoint(mediumLow down) {
		font-size: 3rem;
	}
}

%o-title-josefin-sans-bold-36 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.6rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-35 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.5rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-28 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2.8rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-24 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2.4rem;
	font-weight: 700;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-josefin-sans-bold-20 {
	font-family: $font-josefin-sans-Bold;
	font-size: 2rem;
	font-weight: 700;

	@include breakpoint(mediumLow down) {
		font-size: 1.8rem;
	}
}

%o-title-josefin-sans-bold-18 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-16 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-14 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.4rem;
	font-weight: 700;
}

%o-title-josefin-sans-bold-12 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.2rem;
	font-weight: 700;
}

%o-title-josefin-sans-medium-15 {
	font-family: $font-josefin-sans-SemiBold;
	font-size: 1.5rem;
	font-weight: 700;
}

%o-title-josefin-sans-regular-16 {
	font-family: $font-poppins-Regular;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-poppins-bold-32 {
	font-family: $font-poppins-Bold;
	font-size: 3.2rem;
	font-weight: 700;
}

%o-title-poppins-bold-28 {
	font-family: $font-poppins-Bold;
	font-size: 2.8rem;
	font-weight: 700;
}

%o-title-poppins-bold-24 {
	font-family: $font-poppins-Bold;
	font-size: 2.4rem;
	font-weight: 700;
}

%o-title-poppins-bold-22 {
	font-family: $font-poppins-Bold;
	font-size: 2.2rem;
	font-weight: 700;
}

%o-title-poppins-bold-18 {
	font-family: $font-poppins-Bold;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-title-poppins-bold-16 {
	font-family: $font-poppins-Bold;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-poppins-bold-13 {
	font-family: $font-poppins-Bold;
	font-size: 1.3rem;
	font-weight: 700;
}

%o-title-poppins-semibold-10 {
	font-family: $font-poppins-SemiBold;
	font-size: 1rem;
	font-weight: 600;
}

%o-title-poppins-medium-16 {
	font-family: $font-poppins-Medium;
	font-size: 1.6rem;
	font-weight: 500;
}

%o-title-poppins-medium-15 {
	font-family: $font-poppins-Medium;
	font-size: 1.5rem;
	font-weight: 500;
}

%o-title-poppins-regular-11 {
	font-family: $font-poppins-Regular;
	font-size: 1.1rem;
	font-weight: 400;
}

%o-title-poppins-regular-14 {
	font-family: $font-poppins-Regular;
	font-size: 1.4rem;
	font-weight: 400;
}

%o-title-poppins-regular-24 {
	font-family: $font-poppins-Regular;
	font-size: 2.4rem;
	font-weight: 400;
}

%o-title-poppins-light-14 {
	font-family: $font-poppins-Light;
	font-size: 1.4rem;
	font-weight: 400;
}

%o-title-poppins-light-16 {
	font-family: $font-poppins-Light;
	font-size: 1.6rem;
	font-weight: 300;
}
