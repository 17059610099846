.list-icon-d {
	$this: &;

	&__item-icon-title {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
	}

	&__item-icon {
		flex: 0 0 auto;
		padding-right: 1.2rem;
	}

	&__item-title {
		@extend %o-title-josefin-sans-bold-24;
		flex: 1 1 0;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 0;
	}

	&__item-text {
		margin-top: 1.5rem;

		p {
			font-size: 1.8rem;
		}
	}
}
