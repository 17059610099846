.newsletter {
	$this: &;

	&__cell {
		background-color: #28a194;
		background-image: url("../images/bg-newsletter.png");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		padding: 10rem 0;
		border-radius: 0.8rem;
		margin-bottom: 12rem;

		@include breakpoint(mediumLow down) {
			margin-bottom: 10rem;
			padding: 5rem 0;
		}
	}

	&__content {
		margin: 0 auto;
		max-width: 50rem;

		.single & {
			max-width: 80rem;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-40;
		color: $color-white;
		line-height: 1.2;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		margin-top: 1.5rem;

		p {
			@extend %o-text-poppins-light-20;
			color: $color-white;
		}

		.button-box {
			margin-top: 4rem;
		}
	}

	&__form {
		margin: 3.2rem auto 0;
		max-width: 50rem;

		.single & {
			max-width: 80rem;
		}

		@include breakpoint(1023px down) {
			margin-top: 3rem;
		}

		input.wpcf7-text {
			font-family: $font-poppins-Light;
			background-color: transparent;
			border: 1px solid rgba(255, 255, 255, 0.2);
			color: $color-white;
			margin-bottom: 0;
			font-weight: 300;

			@include breakpoint(767px down) {
				text-align: center;
			}

			&::placeholder {
				color: $color-white;
			}
		}

		.wpcf7-submit {
			letter-spacing: 1px;
			padding-left: 2.2rem;
			padding-right: 2.2rem;
		}
	}

	&.wpcf7 {
		input.wpcf7-text {
			font-family: $font-poppins-Light;
			background-color: transparent;
			border: 1px solid rgba(255, 255, 255, 0.2);
			color: $color-white;
			margin-bottom: 0;
			font-weight: 300;

			@include breakpoint(767px down) {
				text-align: center;
			}

			&::placeholder {
				color: $color-white;
			}
		}

		.wpcf7-submit {
			letter-spacing: 1px;
			padding-left: 2.2rem;
			padding-right: 2.2rem;
		}
	}

	&.hbspt-demo {
		.form-columns-1,
		.form-columns-2 {
			margin-bottom: 0 !important;
		}

		.hs-recaptcha {
			margin-top: 1.5rem;

			.grecaptcha-badge {
				margin: 0 auto;
			}
		}

		.hs-form-field {
			label {
				font-family: $font-poppins-Light;
				color: $color-white;
				font-size: 1.6rem;
				line-height: 1.8;
				margin-bottom: 0;
				font-weight: 300;
			}

			input,
			textarea {
				font-family: $font-poppins-Light;
				background-color: transparent;
				border: 1px solid rgba(255, 255, 255, 0.2);
				color: $color-white;
				margin-bottom: 0;
				font-weight: 300;
				padding: 0 2rem;

				@include breakpoint(767px down) {
					text-align: center;
				}

				&::placeholder {
					color: $color-white;
				}
			}

			input {
				height: 6rem;
			}

			textarea {
				height: 12rem;
				padding: 2rem;
			}
		}

		.hs-form-booleancheckbox-display {
			@include flexbox();
			@include align-items(center);

			input {
				@include flex(0 1 auto);
				@include appearance(auto);

				height: auto;
				width: auto;
			}

			span {
				@include flex(0 1 auto);
			}
		}

		.hs-submit {
			margin-top: 1.5rem;
			text-align: center;

			input {
				$trans: 0.4s ease 0s;

				border: 0 none;
				background-color: $color-yellow;
				color: $color-dark-gray;
				font-family: $font-poppins-SemiBold;
				font-size: 1.6rem;
				height: 6rem;
				letter-spacing: 0.1rem;
				min-width: 17.5rem;
				text-transform: uppercase;
				padding-left: 2.2rem;
				padding-right: 2.2rem;
				width: auto;
				transition: background-color $trans, color $trans;

				&:hover {
					color: $color-white;
				}
			}
		}

		.hs-error-msgs {
			label {
				color: $color-red;
				font-size: 1.4rem;
			}
		}

		.submitted-message {
			font-family: $font-poppins-Light;
			color: $color-white;
			font-size: 1.6rem;
			font-weight: 300;
			text-align: center;

			* {
				color: inherit;
				font: inherit;
			}
		}
	}
}
