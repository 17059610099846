.connect-form {
	$this: &;
	background-color: rgba(59, 123, 206, 0.06);
	padding: 3.5rem 3rem;

	&__title {
		@extend %o-title-poppins-bold-18;
		color: $color-dark-gray-3;
		margin-bottom: 1.5rem;
		text-align: center;
	}

	&__label {
		@extend %o-text-poppins-light-14;
		color: $color-dark-gray-3;
		margin-bottom: 0.5rem;
	}

	&__input-text,
	select#{$this}__select {
		@extend %o-text-poppins-regular-14;
		height: auto;
		padding: 0.8rem 1.7rem;
		background-color: $color-white;
		color: $color-light-gray-11;
		border: 0;

		&:focus {
			color: $color-light-gray-11;
			box-shadow: none;
			border: 0;
		}
	}

	select#{$this}__select {
		background-position: center right 0.7rem;
	}

	.button {
		margin-top: 0.5rem;
	}

	.hs-form-field {
		label {
			@extend #{$this}__label;
		}

		input {
			@extend #{$this}__input-text;
		}

		select {
			@extend #{$this}__select;
		}

		.hs-form-booleancheckbox {
			input {
				width: auto;

				@include appearance(auto);
			}
		}
	}

	.hs-submit {
		input {
			@extend %button;
		}
	}

	.hs-error-msgs {
		label {
			color: $color-red;
			font-size: 1.4rem;
		}
	}

	.submitted-message {
		font-family: $font-poppins-Light;
		color: $color-green-2;
		font-size: 1.6rem;
		font-weight: 300;
		text-align: center;
	}
}
