.photo-content-d {
	$this: &;

	&__content {
		@include breakpoint(medium up) {
			@include auto();
			order: 1;
		}

		@include breakpoint(mediumLow down) {
			width: 91.66667%;
			left: 1rem;
			position: relative;
		}
	}

	&__title {
		color: $color-black;
		font-family: $font-josefin-sans-Bold;
		font-size: 3.3rem;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 3.2rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__header-title {
		@extend #{$this}__title;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 4rem;
		text-transform: none;
		font-size: 3.4rem;

		@include breakpoint(767px down) {
			font-size: 2.8rem;
		}
	}

	&__text {
		margin-top: -0.3rem;

		p,
		li {
			color: #3c3c3c;
			line-height: 1.6;
			font-size: 1.8rem;
		}

		ul {
			padding-left: 0;
		}

		strong {
			font-family: $font-poppins-Bold;
		}

		h3 {
			font-family: $font-josefin-sans-Bold;
			font-size: 2.4rem;
			line-height: 3rem;
			margin: 2.5rem 0;
		}

		.button-box {
			margin-top: 4rem;
		}
	}

	&__media {
		position: relative;

		@include breakpoint(768px up) {
			@include shrink();
			width: 100%;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
		}

		.o-card-bg {
			img {
				position: relative;
				height: auto;
			}
		}

		&--background {
			@include breakpoint(large) {
				margin-bottom: -8rem;
				right: -9rem;
			}
		}

		.o-video-i__img {
			padding-top: 56.25%;
		}
	}

	&__slider {
		position: relative;

		@include breakpoint(mediumLow down) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__swiper {
		padding-bottom: 0;

		@include breakpoint(mediumLow down) {
			padding-bottom: 0;
		}
	}

	&__swiper-pagination {
		@include breakpoint(mediumLow down) {
			padding-bottom: 1rem;
		}
	}

	&__swiper-button-prev {
		@include breakpoint(768px up) {
			left: 0;
		}

		@include breakpoint(mediumLow down) {
			&::after {
				content: url("../images/chevron-left-6-dedede.svg");
			}
		}
	}

	&__swiper-button-next {
		@include breakpoint(768px up) {
			right: 0;
		}

		@include breakpoint(mediumLow down) {
			&::after {
				content: url("../images/chevron-right-6-dedede.svg");
			}
		}
	}

	&__cell {
		z-index: 2;

		&--boxed {
			overflow: hidden;

			@include breakpoint(small) {
				padding: 4.6rem 2.2rem;
			}

			@include breakpoint(large) {
				padding: 10rem 9.2rem;
			}

			#{$this}__media {
				@include breakpoint(767px down) {
					.hide-img-mobile & {
						display: none;
					}
				}
			}

			#{$this}__text {
				li,
				p {
					font-family: $font-poppins-Light;
					font-size: 2rem;
				}

				strong {
					font-family: $font-poppins-Bold;
					font-weight: normal;
				}
			}
		}

		&--left-lined {
			background-image: url("../images/bg-vertical-colored-strip.svg");
			background-repeat: no-repeat;
			background-size: auto 101%;

			@include breakpoint(small) {
				background-position: -2.5rem center;
			}

			@include breakpoint(large) {
				background-position: left center;
			}
		}

		&--offset-overlap {
			@include breakpoint(small) {
				margin-top: 0;
				margin-bottom: 0;
			}

			@include breakpoint(large) {
				margin-top: -16rem;
			}
		}
	}

	&__grid {
		&--offset-overlap {
			margin-bottom: -20.5rem;
		}
	}

	.o-video-i {
		.btn-play {
			opacity: 0.5;
		}
	}
}
