.column-content-d {
	$this: &;
	$gap: 1.5rem;
	$gap_m: 0;

	&__cell {
		overflow: hidden;
	}

	&__row {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(mediumLow down) {
			margin-left: #{$gap_m * -1};
			margin-right: #{$gap_m * -1};
		}
	}

	&__col {
		margin-bottom: 8rem;
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};

		@include breakpoint(1023px down) {
			margin-bottom: 4rem;
		}

		@include breakpoint(mediumLow down) {
			padding-left: #{$gap_m * 1};
			padding-right: #{$gap_m * 1};
		}
	}
}

.column-content-dt {
	$this: &;
	$gap: 3.2rem;
	$gap_m: 0;

	@extend %background-image;
	background-position: center top !important;

	&.item-cnt-odd {
		#{$this}__col {
			@include breakpoint(large up) {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.item-cnt-even {
		#{$this}__col {
			@include breakpoint(large up) {
				&:nth-last-child(1),
				&:nth-last-child(2) {
					margin-bottom: 0;
				}
			}
		}
	}

	&__grid {
		padding-top: 10rem;

		@include breakpoint(1024px down) {
			padding-top: 8rem;
		}

		@include breakpoint(mediumLow down) {
			padding-top: 6rem;
		}
	}

	&__row {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(mediumLow down) {
			margin-left: #{$gap_m * -1};
			margin-right: #{$gap_m * -1};
		}
	}

	&__col {
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};
		margin-bottom: 7.5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(mediumLow down) {
			padding-left: #{$gap_m * 1};
			padding-right: #{$gap_m * 1};
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-40;
		color: $color-black;
		line-height: 1.2;
		text-align: center;
		margin-top: 0;
		margin-bottom: 7.5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
		}

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}
}

.column-content-wb {
	$this: &;
	$gap: 3rem;
	$gap_m: 0;

	@extend %background-image;

	&.no-bg {
		#{$this}__grid {
			padding: 0;
		}
	}

	&__grid {
		padding: 17.5rem 0;

		@include breakpoint(1279px down) {
			padding: 10rem 0;
		}

		@include breakpoint(mediumLow down) {
			padding: 5rem 0;
		}
	}

	&__box {
		position: relative;

		@include breakpoint(1279px down) {
			background-color: #fff;
			padding: 7.5rem 5rem;
		}

		@include breakpoint(mediumLow down) {
			padding: 5rem 2.5rem;
		}
	}

	&__box-bg {
		@extend %fill-container;
		background-color: #fff;
		box-sizing: content-box;
		padding: 7.5rem 5rem;
		top: -7.5rem;
		left: -5rem;
		z-index: 1;

		@include breakpoint(1279px down) {
			display: none;
		}
	}

	&__content {
		width: 33.3333%;
		padding-right: 3rem;
		position: relative;
		z-index: 2;

		.version-b & {
			align-self: center;
			max-width: 56rem;
			padding-right: 0;
			text-align: center;
			width: 100%;
		}

		@include breakpoint(1279px down) {
			width: 75%;
			padding-right: 0;
		}

		@include breakpoint(mediumLow down) {
			width: 100%;
			text-align: center;
		}
	}

	&__list {
		width: 66.6667%;
		padding-left: 3rem;
		overflow: hidden;
		position: relative;
		z-index: 2;

		.version-b & {
			margin-top: 5rem;
			padding-left: 0;
			width: 100%;
		}

		@include breakpoint(1279px down) {
			width: 100%;
			padding-left: 0;
		}

		@include breakpoint(mediumLow down) {
			display: none;
		}
	}

	&__item,
	&__swiper {
		.o-card-t {
			&__text {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: $color-black;
					font-size: 1.8rem;
					text-transform: none;
					margin-bottom: 1rem;
				}

				p {
					color: $color-black;
				}
			}
		}
	}

	&__list-row {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(mediumLow down) {
			margin-left: #{$gap_m * -1};
			margin-right: #{$gap_m * -1};
		}
	}

	&__item {
		width: 33.3333%;
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};

		.col-4 & {
			width: 25%;

			@include breakpoint(1024px down) {
				margin-top: 2rem;
				width: 50%;

				&:first-child,
				&:nth-child(2) {
					margin-top: 0;
				}
			}
		}

		@include breakpoint(mediumLow down) {
			display: none;
		}
	}

	&__slider {
		margin-top: 5rem;
		position: relative;

		@include breakpoint(768px up) {
			display: none;
		}

		@include breakpoint(mediumLow down) {
			padding: 0 3rem;
		}

		.swiper-button-prev,
		.swiper-button-next {
			width: 18px;
			height: 32px;

			&::after {
				font-size: 0;
			}
		}

		.swiper-button-prev {
			left: -2.7rem;

			@include breakpoint(mediumLow down) {
				left: -0.5rem;
			}

			&::after {
				content: url("../images/chevron-left-11-3c3c3c.svg");
			}
		}

		.swiper-button-next {
			right: -2.7rem;

			@include breakpoint(mediumLow down) {
				right: -0.5rem;
			}

			&::after {
				content: url("../images/chevron-right-11-3c3c3c.svg");
			}
		}
	}
}

.column-content-l {
	&__slider {
		position: relative;

		.swiper-slide {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			height: auto;
			text-align: center;
		}

		.swiper-pagination {
			bottom: auto;
			margin-top: 4.5rem;
			position: relative;
		}

		.swiper-pagination-bullet {
			background: #dedede;
			width: 6px;
			height: 6px;
			margin: 2px;
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background-color: #4a4844;
		}

		.swiper-button-prev {
			height: 2rem;
			left: 1.5rem;
			margin-top: -3.5rem;

			&:after {
				content: url("../images/chevron-left-11-3c3c3c.svg");
				font-size: 0;
			}
		}

		.swiper-button-next {
			height: 2rem;
			margin-top: -3.5rem;
			right: 1.5rem;

			&:after {
				content: url("../images/chevron-right-11-3c3c3c.svg");
				font-size: 0;
			}
		}
	}
}

.column-content-b {
	$this: &;

	&__row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);
	}

	&__col {
		padding: 1.5rem;
	}

	.o-card-b,
	.o-card-b__border {
		height: 100%;
		width: 100%;
	}
}

.column-content-li {
	$this: &;
	$gap: 1.5rem;
	$gap_m: 0;

	&__list {
		@include breakpoint(mediumLow down) {
			display: none;
		}
	}

	&__list-row {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(mediumLow down) {
			margin-left: #{$gap_m * -1};
			margin-right: #{$gap_m * -1};
		}
	}

	&__item {
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};
		margin-bottom: 3rem;
	}

	&__slider {
		position: relative;

		@include breakpoint(768px up) {
			display: none;
		}
	}

	&__swiper {
		margin: 0 2.5rem;
	}

	&__swiper-button-prev {
		left: 0;
	}

	&__swiper-button-next {
		right: 0;
	}
}

.column-content-ct {
	$this: &;

	&.title-max-width-850 {
		#{$this}__title {
			max-width: 85rem;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.1;
		margin: 0 auto 5rem;
		max-width: 70rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		padding: 0 1.5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 4rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(767px down) {
			padding: 0;
		}
	}
}

.column-content-f {
	$this: &;

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.1;
		margin: 0 auto 5rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		padding: 0 1.5rem;
		position: relative;

		&:last-child {
			.o-card-ccf {
				&:after {
					display: none;
				}
			}
		}

		@include breakpoint(1024px down) {
			margin-bottom: 4rem;

			&:nth-child(2n) {
				.o-card-ccf {
					&:after {
						display: none;
					}
				}
			}
		}

		@include breakpoint(767px down) {
			padding: 0;

			.o-card-ccf {
				&:after {
					display: none;
				}
			}

			.o-card-ccf__img {
				height: 11rem;
				line-height: 11rem;
				width: 11rem;

				img {
					width: 37%;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.column-content-con {
	.o-card-con {
		&__text {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__col {
		$gap: 3rem;

		width: 50%;

		&:nth-child(1) {
			@include breakpoint(1024px up) {
				padding-right: $gap;
			}
		}

		&:nth-child(2) {
			@include breakpoint(1024px up) {
				padding-left: $gap;
			}
		}

		@include breakpoint(1023px down) {
			width: 100%;
			margin-bottom: 5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__form-container {
		background-color: $color-white;
		box-shadow: 0.2rem 0.2rem 2.5rem rgba(0, 0, 0, 0.1);
		padding: 5rem;

		@include breakpoint(1023px down) {
			padding: 4rem;
		}
	}

	&__form-title {
		@extend %o-title-josefin-sans-bold-40;
		line-height: 1;
		text-align: center;
		margin-bottom: 6.5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 4rem;
		}
	}

	&__content {
		margin-top: 5rem;
		margin-bottom: 5rem;

		@include breakpoint(1023px down) {
			margin-top: 3rem;
			margin-bottom: 3rem;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.column-content-cf {
	$this: &;

	.o-card-con__text {
		margin-top: 0;
	}

	&__col {
		$gap: 3rem;

		width: 50%;

		&:nth-child(1) {
			@include breakpoint(1024px up) {
				padding-right: $gap;
			}
		}

		&:nth-child(2) {
			@include breakpoint(1024px up) {
				padding-left: $gap;
			}
		}

		@include breakpoint(1023px down) {
			width: 100%;
			margin-bottom: 5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&--form {
			padding: 0 5rem !important;

			@include breakpoint(1023px down) {
				padding: 0 !important;
			}
		}
	}

	&__form-title {
		@extend %o-title-josefin-sans-bold-42;
		line-height: 1;
		margin-bottom: 1rem;
	}

	&__content {
		background-color: #fff;
		box-shadow: 2px 2px 25px rgb(0, 0, 0, 0.1);
		margin-top: 5rem;
		margin-bottom: 5rem;
		padding: 6.5rem;
		width: 100%;

		@include breakpoint(1280px down) {
			padding: 6.5rem 4rem;
		}

		@include breakpoint(1023px down) {
			margin-top: 3rem;
			margin-bottom: 3rem;
			padding: 4rem;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.column-content-v {
	$this: &;

	&__row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);
	}

	&__content {
		margin-bottom: 3rem;
		padding: 0 1.5rem;
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: #000;
		line-height: 1.2;
		margin-bottom: 1rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		p {
			font-size: 1.8rem;
		}
	}

	&__item {
		margin-bottom: 2.5rem;
		padding: 0 1.5rem;

		&:nth-child(2n) {
			#{$this}__item-box {
				background-color: #28a194;
			}
		}
	}

	&__item-box {
		@extend %background-image;
		background-color: #3b7bce;
		border-radius: 0.8rem;
		height: 100%;
		padding: 4rem 3rem;
		position: relative;
	}

	&__item-content {
		.one-half & {
			width: calc(50% - 4rem);

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}

	&__item-title {
		@extend %o-title-poppins-bold-18;
		color: #fff;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__item-text {
		p {
			color: #fff;
			font-size: 1.6rem;
		}
	}

	&__item-play {
		margin-top: 1rem;
	}
}

.column-content-fcl {
	$this: &;

	&__content {
		@include breakpoint(1023px down) {
			margin-bottom: 2rem;
		}
	}

	&__title {
		@extend %o-title-poppins-bold-24;
		line-height: 1.2;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__filter {
		margin-top: 1.5rem;

		ul,
		ol {
			li {
				color: $color-green;
				cursor: pointer;
				font-size: 1.6rem;
				line-height: 1.3;
				margin-bottom: 1.5rem;
				transition: color 0.4s ease;

				&.active {
					font-family: $font-poppins-SemiBold;
				}

				&:hover {
					color: $color-blue;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__text {
		margin-top: 2rem;

		p {
			font-size: 1.6rem;
		}
	}

	&__list {
		position: relative;
	}

	&__list-box {
		margin-left: -1.5rem;
		opacity: 1;
		position: relative;
		width: calc(100% + 3rem);
		z-index: 2;
		transition: opacity 0.4s ease;

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}

		&.hidden {
			opacity: 0;
		}
	}

	&__list-card-item {
		padding: 0 1.5rem;

		@include breakpoint(767px down) {
			padding: 0;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.o-card-spt {
			display: flex;
			flex-flow: column wrap;
			height: 100%;
		}

		.o-card-spt__img {
			flex: 0 0 auto;
		}

		.o-card-spt__content {
			flex: 1 1 0;
			display: flex;
			flex-flow: row wrap;
		}

		.o-card-spt__top {
			flex: 0 1 auto;
		}

		.o-card-spt__text {
			flex: 0 1 auto;
			align-self: flex-end;
			width: 100%;
		}
	}

	&__list-title {
		padding: 0 1.5rem;

		@include breakpoint(1024px down) {
			margin-top: 2rem;
		}

		@include breakpoint(767px down) {
			padding: 0;
		}

		ul,
		ol {
			li {
				font-family: $font-poppins-Medium;
				font-size: 1.6rem;
				line-height: 1.3;
				margin-bottom: 1.5rem;

				a {
					color: $color-green;
					font: inherit;
					transition: color 0.4s ease;

					&:hover {
						color: $color-blue;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__list-loader {
		opacity: 1;
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
		transition: opacity 0.4s ease;

		&.hidden {
			opacity: 0;
		}
	}

	&__list-message {
		@extend %o-title-poppins-bold-18;
		line-height: 1.2;
		margin-bottom: 0;
		text-align: center;
		width: 100%;
	}
}

.column-content-cl {
	$this: &;

	&__img {
		padding-right: 3rem;

		@include breakpoint(large down) {
			margin-bottom: 3rem;
			padding-right: 0;
			text-align: center;
		}
	}

	&__content {
		padding-left: 3rem;

		@include breakpoint(large down) {
			padding-left: 0;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 1.5rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		p {
			font-size: 1.8rem;
		}
	}

	&__icons {
		margin-left: -2.5rem;
		margin-top: 6rem;
		width: calc(100% + 5rem);

		@include breakpoint(1023px down) {
			margin-top: 0;
			margin-left: 0;
			width: 100%;
		}
	}

	&__icon {
		margin-bottom: 5rem;
		padding: 0 2.5rem;

		@include breakpoint(1023px down) {
			padding: 0;
		}
	}

	&__icon-title {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
	}

	&__icon-icon {
		flex: 0 0 auto;
		padding-right: 1.2rem;
	}

	&__icon-title {
		@extend %o-title-josefin-sans-bold-24;
		flex: 1 1 0;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 0;
	}

	&__icon-text {
		margin-top: 1.5rem;

		p {
			font-size: 1.8rem;
		}
	}

	&__pattern {
		text-align: right;

		@include breakpoint(1023px down) {
			display: none;
		}
	}
}
