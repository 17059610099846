.popup-st {
	$this: &;

	background-color: $color-white;
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	transition: opacity 0.4s ease 0s, z-index 0s ease 0.4s;

	@include breakpoint(1023px down) {
		overflow: auto;
	}

	&.active {
		opacity: 1;
		pointer-events: all;
		z-index: 50;
		transition: opacity 0.4s ease 0s, z-index 0s ease 0s;
	}

	&__close {
		width: 21px;
		height: 21px;
		cursor: pointer;
		position: absolute;
		top: 4rem;
		right: 4rem;

		@include breakpoint(767px down) {
			top: 2rem;
			right: 2rem;
		}

		&:before,
		&:after {
			background-color: $color-dark-gray-4;
			display: block;
			content: "";
			width: 100%;
			height: 1px;
			margin-top: -1px;
			position: absolute;
			top: 50%;
			left: 0;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	&__grid {
		@include breakpoint(768px up) {
			height: 100%;
		}
	}

	&__person {
		background: linear-gradient(165deg, #28a194 10.57%, #3b7bce 105.67%);
		width: 40%;

		@include breakpoint(1023px down) {
			width: 100%;
		}

		.o-card-st {
			text-align: center;

			img {
				width: auto;
			}

			&__name {
				color: $color-white;
				font-size: 2.4rem;
				text-transform: none;
				margin-bottom: 1.5rem;
			}

			&__pos {
				margin-bottom: 1.5rem;

				p {
					color: $color-white;
					font-size: 1.6rem;
				}
			}

			&__social {
				a {
					display: inline-block;
				}

				svg {
					path {
						fill: $color-white;
					}
				}
			}
		}
	}

	&__person-container {
		padding: 7.5rem;

		@include breakpoint(767px down) {
			padding: 5rem 4rem;
		}
	}

	&__content {
		width: 60%;

		@media (min-width: 1280px) and (max-height: 899px) {
			height: 100%;
		}

		@include breakpoint(1023px down) {
			width: 100%;
		}
	}

	&__content-container {
		padding: 7.5rem;

		@media (min-width: 1280px) and (max-height: 899px) {
			height: 100%;
		}

		@include breakpoint(767px down) {
			padding: 5rem 4rem;
		}

		p {
			color: $color-dark-gray-3;
			font-size: 1.8rem;
		}
	}

	&__content-text {
		@media (min-width: 1280px) and (max-height: 899px) {
			height: 100%;
			overflow-y: auto;
		}
	}
}
