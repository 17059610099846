.o-color-bar {
	$this: &;

	&.horizontal {
		height: 25px;
		overflow: hidden;
		position: relative;

		#{$this}__box {
			width: 25%;
			height: 500%;
			top: -250%;

			@for $i from 1 through 5 {
				&.cnt-#{$i} {
					left: #{($i - 1) * 20 * 1%};

					@if ($i != 5) {
						div {
							left: -40%;
						}
					}
				}
			}

			div {
				width: 140%;
				height: 100%;
				top: 0;
				left: -20%;
			}
		}
	}

	&__box {
		position: absolute;

		&.sloped {
			div {
				transform: rotate(-60deg);
			}
		}

		&.green {
			div {
				background-color: $color-green;
			}
		}

		&.yellow {
			div {
				background-color: $color-yellow;
			}
		}

		&.blue {
			div {
				background-color: $color-blue;
			}
		}

		&.white {
			div {
				background-color: $color-white;
			}
		}

		div {
			position: absolute;
		}
	}
}
