.table-c {
	$this: &;

	&__cell {
		@include breakpoint(767px down) {
			overflow-x: auto;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;

		tr {
			border-bottom: 0.1rem solid $color-light-gray-13;

			&.no-border {
				border-bottom: 0;
			}

			&:first-child,
			&:last-child {
				border-bottom: 0;
			}
		}

		td {
			color: #25291c;
			font-family: $font-poppins-Light;
			font-size: 1.6rem;
			font-weight: 300;
			padding: 3.5rem 2rem;
			position: relative;
			text-align: center;
			width: 33.33%;

			&:first-child {
				padding-left: 0;
				text-align: left;
			}

			&.highlight {
				border-left: 0.4rem solid $color-yellow;
				border-right: 0.4rem solid $color-yellow;
			}

			&.no-padding {
				padding: 0;
			}

			.button {
				display: block;
				padding: 2rem;
			}
		}

		th {
			position: relative;
			width: 33.33%;

			&.set-table-head {
				background-color: $color-blue;
				border-left: 0.4rem solid $color-blue;
				border-right: 0.4rem solid $color-blue;
				color: $color-white;
				font-family: $font-josefin-sans-SemiBold;
				font-size: 2.4rem;
				line-height: 1;
				padding: 2.5rem 1.2rem;

				&.plain-text {
					background-color: transparent;
					border-left: 0;
					border-right: 0;
				}

				strong {
					font-family: $font-josefin-sans-Bold;
				}
			}

			&.plain-text {
				color: $color-black;
				font-family: $font-poppins-Bold;
				font-size: 2rem;
				line-height: 1;
			}
		}
	}
}
