.pricing-d {
	$this: &;

	&__row {
		margin-left: -1.75rem;
		width: calc(100% + 3.5rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		margin-bottom: 3.5rem;
		padding: 0 1.75rem;

		@include breakpoint(767px down) {
			padding: 0;
		}

		.o-underline {
			border-bottom: 0;
			text-decoration: underline;
		}

		&.most-popular {
			.o-card-pd {
				background: rgb(40, 161, 148);
				background: -moz-linear-gradient(
					180deg,
					rgba(40, 161, 148, 1) 0%,
					rgba(59, 123, 206, 1) 100%
				);
				background: -webkit-linear-gradient(
					180deg,
					rgba(40, 161, 148, 1) 0%,
					rgba(59, 123, 206, 1) 100%
				);
				background: linear-gradient(
					180deg,
					rgba(40, 161, 148, 1) 0%,
					rgba(59, 123, 206, 1) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#28a194",endColorstr="#3b7bce",GradientType=1);
			}

			.o-card-pd__title,
			.o-card-pd__subtitle,
			.o-card-pd__item-text {
				color: $color-white;
			}

			.o-card-pd__item-text {
				svg,
				circle {
					stroke: $color-white;
				}
			}

			.o-card-pd__item {
				&:before {
					background-color: $color-white;
				}
			}

			.o-card-pd__content {
				p {
					color: $color-white;
				}
			}
		}
	}

	&__link {
		margin-top: 2.5rem;
	}

	&__table {
		display: none;
		padding-top: 4.5rem;
		position: relative;

		&:before {
			border-bottom: 4rem solid $color-blue-5;
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			content: "";
			height: 0;
			left: 50%;
			position: absolute;
			top: 0.5rem;
			width: 0;
			transform: translateX(-50%);
		}

		&:after {
			background-color: $color-blue-5;
			content: "";
			height: calc(100% - 4.5rem);
			position: absolute;
			top: 4.5rem;
			left: 0;
			width: 100%;
		}
	}

	&__table-grid {
		position: relative;
		z-index: 2;
	}

	&__table-cell {
		padding: 12rem 0 16rem;

		@include breakpoint(767px down) {
			padding: 5rem 0;
		}
	}

	&__table-title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-dark-gray-3;
		line-height: 1.2;
		margin-bottom: 5rem;
	}

	&__table-box {
		overflow: auto;

		table {
			background-color: $color-white;
			border-collapse: separate;
			border-spacing: 0;
			box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.1);
			margin-top: 5rem;
			position: relative;
			width: 100%;

			tbody {
				&:before,
				&:after {
					background: $color-white;
					content: "";
					height: 100%;
					position: absolute;
					top: 0;
					width: 3rem;
					z-index: 2;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}
			}

			tr {
				&:last-child {
					td {
						border-bottom: 0;

						&.highlight {
							border-bottom: 0.4rem solid $color-yellow;
						}
					}
				}

				&:first-child {
					position: relative;
					z-index: 3;
				}
			}

			td {
				border-bottom: 0.1rem solid $color-light-gray-13;
				color: $color-dark-gray-3;
				font-family: $font-poppins-Light;
				font-size: 1.6rem;
				font-weight: 300;
				line-height: 1.2;
				padding: 1.7rem 3rem;
				position: relative;
				text-align: center;
				width: 18.66%;

				&:first-child {
					text-align: left;
					width: 44%;
				}

				&.highlight {
					border-left: 0.4rem solid $color-yellow;
					border-right: 0.4rem solid $color-yellow;
				}
			}

			th {
				position: relative;
				text-align: center;
				width: 18.66%;

				&:first-child {
					text-align: left;
					width: 44%;
				}

				&.set-table-head {
					background-color: $color-blue;
					border-left: 0.4rem solid $color-blue;
					border-right: 0.4rem solid $color-blue;
					color: $color-white;
					font-family: $font-josefin-sans-SemiBold;
					font-size: 2.4rem;
					font-weight: 600;
					line-height: 1.2;
					padding: 3rem;

					strong {
						font-family: $font-josefin-sans-Bold;
						font-weight: 700;
					}
				}

				&.highlight {
					border-left-color: $color-yellow;
					border-right-color: $color-yellow;
				}

				.highlight-text {
					@extend %o-text-poppins-semibold-14;
					background-color: $color-yellow;
					color: $color-dark-gray-3;
					left: -1.5rem;
					letter-spacing: 0.1rem;
					padding: 1.7rem 2.8rem;
					position: absolute;
					text-transform: uppercase;
					top: -4.5rem;
					width: calc(100% + 3rem);
					z-index: 2;
				}
			}
		}
	}
}
