.o-bullet-d {
	&.green {
		ul {
			li {
				&:before {
					background-color: $color-green;
				}
			}
		}

		ol {
			li {
				&:before {
					color: $color-green;
				}
			}
		}
	}

	ol,
	ul {
		padding-left: 0.7rem;
	}

	ul {
		li {
			padding-left: 2.2rem;

			&:before {
				background-color: $color-black;
				border-radius: 50%;
				content: "";
				height: 1rem;
				left: 0;
				position: absolute;
				top: 0.8rem;
				width: 1rem;
			}
		}
	}

	ol {
		counter-reset: number;

		li {
			counter-increment: number;
			margin-left: 2rem;

			&:before {
				color: $color-black;
				content: counter(number);
				left: -3rem;
				position: absolute;
				text-align: center;
				top: 0;
				width: 2rem;
			}
		}
	}

	li {
		@extend %o-text-poppins-light-16;
		color: $color-black;
		line-height: 1.6;
		position: relative;

		* {
			color: inherit;
			font: inherit;
		}

		a {
			color: $color-green;
			font: inherit;
			text-decoration: underline;

			@include transition(color 0.4s ease);

			&:hover {
				color: $color-dark-gray-2;
			}
		}

		strong {
			font-family: $font-poppins-Bold;
		}
	}
}
