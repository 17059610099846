.photo-content-sl {
	$this: &;
	background-image: url("../images/bg-photo-content-sl.svg");
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	padding-top: 22rem;

	&.left-content {
		@include breakpoint(1024px up) {
			#{$this}__img {
				order: 2;
				padding-left: 10%;
			}

			#{$this}__content {
				order: 1;
			}
		}
	}

	&.right-content {
		@include breakpoint(1024px up) {
			#{$this}__img {
				padding-right: 10%;
			}
		}
	}

	&__img {
		position: relative;

		img {
			width: 100%;
		}

		@include breakpoint(768px up) {
			width: 50%;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
			order: 1;
		}

		@include breakpoint(767px down) {
			margin-bottom: 3rem;

			.hide-img-mobile & {
				display: none;
			}
		}
	}

	&__content {
		@include breakpoint(1024px up) {
			width: 50%;
		}

		@include breakpoint(1023px down) {
			order: 2;
		}
	}

	&__pretitle {
		@extend %o-title-poppins-bold-16;
		color: $color-blue;
		line-height: 1.2;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}

	&__title {
		@extend %o-title-josefin-sans-bold-40;
		color: $color-black;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
		}

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__slider {
		padding-bottom: 6rem;
		position: relative;

		svg {
			position: absolute;
			top: -4rem;
			left: -5rem;
		}
	}

	&__swiper {
		padding: 10px;
		margin-left: -1rem;
	}

	&__swiper-slide {
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		overflow: hidden;

		.button-box {
			margin-top: 2rem;
		}
	}

	&__slider-bar {
		margin-top: 5rem;
		position: relative;

		@include breakpoint(767px down) {
			margin-top: 2rem;
		}

		&:before {
			background-color: #c9ced0;
			border-radius: 4px;
			display: block;
			content: "";
			width: 100%;
			height: 4px;
			position: absolute;
			top: 2px;
			left: 0;
			z-index: 1;
		}

		div {
			background-color: $color-yellow;
			border-radius: 8px;
			width: 100%;
			height: 8px;
			overflow: hidden;
			position: relative;
			z-index: 2;
		}
	}

	&__slider-cnt {
		font-size: 0;
		position: absolute;
		bottom: 0;
		right: 0;

		span {
			color: $color-light-gray-6;
			font-family: $font-poppins-SemiBold;
			font-size: 1.6rem;

			&#{$this}__slider-cnt-current {
				color: $color-blue;
				font-family: $font-poppins-Bold;
				font-size: 3.2rem;
				padding-right: 2px;
			}
		}
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		width: 32px;
		height: 32px;
		margin-top: 0;
		top: auto;
		bottom: 8px;

		&:after {
			display: none;
		}
	}

	&__swiper-button-prev {
		left: 0;
		transform: rotate(180deg);
	}

	&__swiper-button-next {
		left: 52px;
		right: auto;
	}

	&__text {
		p {
			color: $color-dark-gray-2;
			line-height: 1.5;
		}

		.button-box {
			margin-top: 4rem;
		}
	}

	&__obj {
		&--paper-bulb {
			background-image: url("../images/obj-paper-light-bulb.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 16rem;
			height: 19rem;
			position: absolute;
			z-index: 1;
			top: -22rem;
		}
	}
}
