.post-search-d {
	$this: &;

	&__aside {
		width: 20%;
		position: relative;
		z-index: 3;

		@include breakpoint(1023px down) {
			width: 100%;
			margin-bottom: 50px;
		}
	}

	&__aside-item {
		margin-bottom: 3.5rem;

		&.item-filter {
			position: relative;
		}

		&.item-category {
			@include breakpoint(1023px down) {
				display: none;
			}
		}

		&.item-select {
			@include breakpoint(1024px up) {
				display: none;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__aside-title {
		@extend %o-title-poppins-bold-18;
		color: $color-black;
		line-height: 1;
		width: 100%;
		margin-bottom: 1.5rem;
	}

	&__search {
		position: relative;

		input {
			@extend %o-text-poppins-regular-16;
			border: 1px solid $color-light-gray-8;
			color: $color-light-gray-8;
			line-height: 4rem;
			width: 100%;
			height: 4rem;
			padding: 1rem 1.5rem;

			@each $prefix in $placeholder_prefixes {
				&#{$prefix} {
					color: $color-light-gray-8;
					opacity: 1;
				}
			}
		}
	}

	&__result {
		background-color: #fff;
		border: 1px solid $color-light-gray-8;
		width: 100%;
		margin-top: -1px;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 2;

		li {
			@extend %o-text-poppins-regular-16;
			border-bottom: 1px solid $color-light-gray-8;
			background-color: $color-white;
			color: $color-black;
			line-height: 1.3;
			margin-bottom: 0;

			&:last-child {
				border-bottom: 0 none;
			}

			p,
			a {
				display: block;
				color: inherit;
				font: inherit;
				padding: 1.2rem 1.5rem;
			}
		}
	}

	&__categories {
		ul {
			li {
				@extend %o-text-poppins-regular-16;
				color: $color-black;
				line-height: 1;
				margin-bottom: 15px;

				&:last-of-type {
					margin-bottom: 0;
				}

				a {
					color: inherit;
					font: inherit;
				}
			}
		}
	}

	&__select {
		background-color: $color-white;
		border: 1px solid $color-light-gray-8;
		position: relative;

		&.active {
			#{$this}__select-text {
				&::before {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}

	&__select-text,
	&__select-option {
		@extend %o-text-poppins-regular-16;
		background-color: $color-white;
		color: $color-black;
		line-height: 1;
	}

	&__select-text {
		padding: 1.2rem 1.5rem;
		position: relative;

		&::before {
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 6px solid $color-light-gray-8;
			display: block;
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%) rotate(0);
		}
	}

	&__select-options {
		position: absolute;
		top: 100%;
		left: 0;

		border: 1px solid $color-light-gray-8;
		box-sizing: content-box;
		width: 100%;
		margin-left: -1px;
		position: absolute;
		top: 100%;
		left: 0;
	}

	&__select-option {
		border-bottom: 1px solid $color-light-gray-8;
		margin-bottom: 0;

		&:last-of-type {
			border-bottom: 0 none;
		}

		a {
			display: block;
			color: inherit;
			font: inherit;
			padding: 1.2rem 1.5rem;
		}
	}

	&__query {
		width: 80%;
		padding-left: 30px;

		@include breakpoint(1023px down) {
			width: 100%;
			padding-left: 0;
		}
	}

	&__featured {
		margin-bottom: 5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 3rem;
		}

		.o-card-ccd {
			background-color: #28a194;
			border-radius: 0;
			box-shadow: 0 none;
			position: relative;

			&__img {
				background-size: cover !important;
				padding-top: 35%;
				width: 33.3333%;

				@include breakpoint(767px down) {
					padding-top: 63.15%;
					width: 100%;
				}

				img {
					opacity: 0;
				}
			}

			&__content {
				background-color: $color-blue;
				width: 66.6667%;
				padding: 7.5rem;

				@include breakpoint(767px down) {
					width: 100%;
					padding: 5rem;
				}
			}

			&__category {
				@extend %o-title-poppins-medium-16;
				color: $color-white;
				letter-spacing: 2px;
				opacity: 1;
			}

			&__title {
				@extend %o-title-poppins-bold-28;
				color: $color-white;
				line-height: 1.5;
				margin-top: 2rem;

				@include breakpoint(767px down) {
					font-size: 2.5rem;
				}
			}

			&__text {
				margin-top: 1rem;

				p {
					@extend %o-text-poppins-light-16;
					color: $color-white;
					line-height: 1.5;
				}
			}
		}
	}

	&__list {
		.o-card-ccd {
			position: relative;

			&__content {
				padding: 3rem;

				> * {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&__category {
				@extend %o-title-poppins-semibold-10;
				color: $color-light-gray-9;
				opacity: 1;
			}

			&__title {
				@extend %o-title-poppins-bold-18;
				color: $color-light-gray-10;
			}

			&__text {
				margin-bottom: 1rem;

				p {
					@extend %o-text-poppins-light-13;
					color: $color-light-gray-9;
					line-height: 1.5;
				}
			}

			&__name {
				@extend %o-title-poppins-bold-13;
				color: $color-light-gray-10;
				line-height: 1.2;
				margin-bottom: 0;
				text-transform: uppercase;
			}

			&__position {
				@extend %o-text-poppins-light-13;
				color: $color-light-gray-10;
				line-height: 1.5;
			}
		}
	}

	&__list-container {
		$gap: 1.5rem;
		$gap_767: 0;

		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(767px down) {
			margin-left: #{$gap_767 * -1};
			margin-right: #{$gap_767 * -1};
		}
	}

	&__item {
		$gap: 1.5rem;
		$gap_767: 0;

		width: 33.33%;
		padding-left: #{$gap};
		padding-right: #{$gap};
		margin-bottom: 3rem;

		@include breakpoint(1023px down) {
			width: 50%;
		}

		@include breakpoint(767px down) {
			width: 100%;
			padding-left: #{$gap_767};
			padding-right: #{$gap_767};
		}

		.o-card-ccd {
			border-radius: 0;
			height: 100%;
		}
	}

	&__pagination {
		text-align: center;
		margin-top: 50px;

		li {
			display: inline-block;
			margin-right: 7px;

			&:last-of-type {
				margin-right: 0;
			}

			a,
			span {
				@extend %o-text-poppins-semibold-11;
				display: block;
				line-height: 30px;
				text-align: center;
				width: 30px;
				height: 30px;
			}

			a {
				background-color: transparent;
				border: 1px solid $color-dark-gray-3;
				color: $color-dark-gray-3;
			}

			span {
				background-color: $color-blue;
				border: 1px solid $color-blue;
				color: #fff;

				&.dots {
					background-color: transparent;
					border: 1px solid $color-dark-gray-3;
					color: $color-dark-gray-3;
				}
			}
		}
	}
}
