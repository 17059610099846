.hero-b {
	$this: &;

	background-image: url("../images/bg-hero.svg");
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	padding: 18rem 0 13rem;

	@include breakpoint(767px down) {
		padding: 15rem 0 5rem;
	}

	&__title {
		@extend %o-title-josefin-sans-bold-52;
		color: $color-black;
		line-height: 1;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}
}
