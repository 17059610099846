%input-submit-button {
	border: 0 none;
	background-color: $color-yellow;
	color: $color-dark-gray;
	font-family: $font-poppins-SemiBold;
	font-size: 1.4rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	min-width: 175px;
	padding: 1.5rem 2rem;
	width: auto;
	height: 48px;
}

.wpcf7-resource {
	$this: &;
	$gap: 1rem;

	label {
		@extend %o-text-poppins-regular-14;
		color: $color-light-gray-9;
		margin-bottom: 1rem;
	}

	input {
		border: 0.1rem solid $color-light-gray-9;
		color: $color-black;
		font-family: $font-poppins-Regular;
		font-size: 1.4rem;
		width: 100%;
		height: 3.5rem;
		padding: 0.5rem 1.5rem;
		margin-bottom: 0;

		@each $prefix in $placeholder_prefixes {
			&#{$prefix} {
				color: $color-light-gray-11;
				opacity: 1;
			}
		}
	}

	p {
		@extend %o-text-poppins-regular-16;
	}

	.grid-x {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(1024px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.cell {
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 3rem;

		@include breakpoint(1024px down) {
			padding-left: 0;
			padding-right: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.wpcf7-form-control-wrap {
		margin-bottom: 0;
	}

	.wpcf7-submit {
		@extend %input-submit-button;
	}

	.wpcf7-spinner {
		display: none;
	}
}

.wpcf7-default {
	$this: &;
	$gap: 1rem;

	.form-columns-1 {
		.input {
			margin-right: 0 !important;
		}
	}

	.form-columns-2 {
		$gap: 1rem;

		display: flex;
		flex-flow: row wrap;
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(767px down) {
			margin-left: 0;
			margin-right: 0;
		}

		.hs-form-field {
			width: 50%;
			padding-left: $gap;
			padding-right: $gap;

			.input {
				margin-right: 0 !important;
			}

			@include breakpoint(767px down) {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	fieldset {
		max-width: none !important;
		margin-bottom: 3rem !important;
	}

	.hs-form-field {
		.hs-form-required {
			color: $color-red-2;
			font-size: 1.2rem;
			padding-left: 0.5rem;
		}
	}

	label {
		@extend %o-text-poppins-regular-14;
		color: $color-light-gray-9;
		display: block;
		line-height: 1;
		margin-bottom: 0.5rem;
	}

	input {
		@extend %o-text-poppins-regular-14;
		background-color: transparent;
		border-color: $color-light-gray-9;
		box-shadow: 0 0 0 transparent;
		color: $color-light-gray-9;
		width: 100% !important;
		height: 3.5rem;
		padding: 0.5rem 0.5rem;
		margin-bottom: 0;
	}

	textarea {
		@extend %o-text-poppins-regular-14;
		background-color: transparent;
		border-color: $color-light-gray-9;
		box-shadow: 0 0 0 transparent;
		color: $color-light-gray-9;
		width: 100% !important;
		min-height: 20rem;
		padding: 0.5rem 0.5rem;
		margin-bottom: 0;
	}

	p {
		@extend %o-text-poppins-regular-14;
	}

	.grid-x {
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(1023px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.cell {
		padding-left: 1rem;
		padding-right: 1rem;
		position: relative;
		margin-bottom: 3rem;

		@include breakpoint(1023px down) {
			padding-left: 0;
			padding-right: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.wpcf7-form-control-wrap {
		margin-bottom: 0;
	}

	.wpcf7-acceptance {
		input {
			@include appearance(auto);
			height: 1rem;
			width: 1rem !important;
		}
	}

	.wpcf7-not-valid-tip {
		bottom: auto;
		display: block;
		left: auto;
		margin-top: 1rem;
		position: relative;
	}

	.wpcf7-submit {
		@extend %input-submit-button;
		width: auto !important;
	}

	.wpcf7-spinner {
		display: none;
	}

	.hs-error-msgs {
		margin-top: 1rem;

		li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.hs-error-msgs label,
	label.hs-error-msg {
		@extend %o-text-poppins-regular-12;
		color: $color-red-2;
		line-height: 1.1;
		margin-bottom: 0;
	}

	.hs-submit {
		margin-top: 1rem;

		.hs-button {
			@extend %input-submit-button;
			width: auto !important;
		}
	}

	.submitted-message {
		font-family: $font-poppins-Light;
		color: $color-green-4;
		font-size: 1.4rem;
		font-weight: 300;
		text-align: center;
	}
}

.hbspt-demo {
	.form-columns-1 {
		.input {
			margin-right: 0;
		}
	}

	.form-columns-2 {
		$gap: 1.5rem;

		display: flex;
		flex-flow: row wrap;
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		@include breakpoint(767px down) {
			margin-left: 0;
			margin-right: 0;
		}

		.hs-form-field {
			width: 50%;
			padding-left: $gap;
			padding-right: $gap;

			@include breakpoint(767px down) {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	fieldset {
		max-width: none !important;
		margin-bottom: 4rem !important;
	}

	.hs-form-field {
		label {
			@extend %o-text-poppins-regular-16;
			color: $color-black;
			line-height: 1;
			margin-bottom: 0;
		}

		input {
			@extend %o-text-poppins-regular-16;
			background-color: transparent;
			border: 0 none;
			border-bottom: 0.1rem solid $color-black;
			box-shadow: 0 0 0 transparent;
			color: $color-black;
			width: 100% !important;
			height: 4.5rem;
			padding: 0.5rem 0.5rem;
			margin-bottom: 0;
		}

		textarea {
			@extend %o-text-poppins-regular-16;
			background-color: transparent;
			border: 0 none;
			border-bottom: 0.1rem solid $color-black;
			box-shadow: 0 0 0 transparent;
			color: $color-black;
			width: 100% !important;
			min-height: 200px;
			padding: 0.5rem 0.5rem;
			margin-bottom: 0;
		}

		.hs-form-required {
			color: $color-red-2;
			padding-left: 0.5rem;
		}
	}

	.hs-fieldtype-radio {
		> label {
			color: $color-black;
			margin-bottom: 2rem;
		}

		> input {
			margin-right: 0;
		}

		.hs-form-radio {
			margin-bottom: 1rem;

			label {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				color: $color-black;
			}

			input {
				flex: 0 0 auto;
				@extend %o-text-poppins-regular-16;
				appearance: radio;
				background-color: transparent;
				box-shadow: 0 0 0 transparent;
				color: $color-black;
				height: 2rem;
				margin-bottom: 0;
				padding: 0.5rem 0.5rem;
				width: auto !important;
			}

			span {
				flex: 1 1 0;
				color: $color-black;
			}
		}
	}

	.hs-error-msgs {
		margin-top: 1rem;

		li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.hs-error-msgs label,
	label.hs-error-msg {
		@extend %o-text-poppins-regular-14;
		color: $color-red-2;
		line-height: 1.1;
	}

	.hs-submit {
		margin-top: 1rem;

		.hs-button {
			@extend %input-submit-button;
		}
	}

	.submitted-message {
		font-family: $font-poppins-Light;
		color: $color-green-4;
		font-size: 1.6rem;
		font-weight: 300;
		text-align: center;
	}
}

.hbspt-default {
	p {
		@extend %o-text-poppins-light-16;
		color: $color-black;
		margin-bottom: 3rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	label {
		@extend %o-text-poppins-regular-14;
		color: $color-light-gray-9;
		margin-bottom: 1rem;
	}

	.hs-form-field {
		margin-bottom: 3rem;

		input {
			border: 0.1rem solid $color-light-gray-9;
			color: $color-black;
			font-family: $font-poppins-Regular;
			font-size: 1.4rem;
			width: 100% !important;
			height: 3.5rem;
			padding: 0.5rem 1.5rem;
			margin-bottom: 0;

			@each $prefix in $placeholder_prefixes {
				&#{$prefix} {
					color: $color-light-gray-11;
					opacity: 1;
				}
			}
		}

		select {
			@extend %o-text-poppins-regular-14;
			background-position: center right;
			border: 0.1rem solid $color-light-gray-9;
			height: 3.5rem;
			padding: 0.8rem 1.7rem;
			background-color: $color-white;
			color: $color-light-gray-11;
			margin-bottom: 0;

			&:focus {
				color: $color-light-gray-11;
				box-shadow: none;
			}
		}
	}

	.hs-form-booleancheckbox-display {
		@include flexbox();
		@include align-items(center);

		input {
			@include flex(0 1 auto);
			@include appearance(auto);

			width: auto;
		}

		span {
			@include flex(0 1 auto);
		}
	}

	.hs-submit {
		margin-top: 1rem;

		input {
			@extend %input-submit-button;
		}
	}

	.submitted-message {
		font-family: $font-poppins-Light;
		color: $color-green-4;
		font-size: 1.6rem;
		font-weight: 300;
		text-align: center;
	}

	// error msgs

	.hs-error-msgs label,
	label.hs-error-msg {
		@extend %o-text-poppins-regular-14;
		color: $color-red-2;
		line-height: 1.1;
	}

	.hs-form-field + .hs_error_rollup {
		margin-top: -1.8rem;
		margin-bottom: 3rem;
	}

	.hs-form-field .input + .hs-error-msgs {
		margin-top: 1rem;
	}
}
