.pillar-d {
	$this: &;

	position: relative;
	z-index: 6;

	&.disable-sidebar {
		#{$this}__items {
			width: 100%;
		}
	}

	&__row {
		position: relative;
	}

	&__nav {
		padding-right: 2.5rem;
		position: absolute;
		top: 0;
		width: 30%;
		z-index: 1;

		@include breakpoint(1023px down) {
			padding-right: 0;
			position: relative;
			top: auto;
			width: 100%;
		}

		&.active {
			#{$this}__nav-text {
				i {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}

		ul {
			@include breakpoint(1023px down) {
				display: none;
				background-color: $color-white;
				border-left: 0.1rem solid $color-black;
				border-right: 0.1rem solid $color-black;
				left: 0;
				position: absolute;
				top: 100%;
				width: 100%;
			}
		}

		li {
			color: $color-black;
			font-family: $font-josefin-sans-Bold;
			font-size: 1.8rem;
			line-height: 1.2;
			margin-bottom: 2rem;
			padding-left: 3.5rem;
			position: relative;

			@include breakpoint(1023px down) {
				border-bottom: 0.1rem solid $color-light-gray-9;
				margin-bottom: 0;
				padding-left: 0;
			}

			&:first-child,
			&:last-child {
				&:after {
					height: 100%;
				}
			}

			&:first-child {
				&:after {
					bottom: auto;
					top: 50%;
				}
			}

			&:last-child {
				&:after {
					bottom: 50%;
				}
			}

			&:before {
				background-color: $color-white;
				border: 0.1rem solid $color-light-gray-9;
				border-radius: 50%;
				content: "";
				height: 1.8rem;
				left: 0;
				position: absolute;
				top: 50%;
				width: 1.8rem;
				transform: translateY(-50%);
				z-index: 2;

				@include breakpoint(1023px down) {
					display: none;
				}
			}

			&:after {
				background-color: $color-light-gray-9;
				bottom: -2rem;
				content: "";
				height: calc(100% + 2rem);
				left: 0.8rem;
				position: absolute;
				width: 0.1rem;

				@include breakpoint(1023px down) {
					display: none;
				}
			}

			a {
				color: inherit;
				font: inherit;
				opacity: 0.5;
				transition: opacity 0.4s ease;

				&.active,
				&:hover {
					opacity: 1;
				}

				@include breakpoint(1023px down) {
					display: block;
					opacity: 1;
					padding: 1rem 2rem;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__nav-text {
		display: none;
		border: 0.1rem solid $color-black;
		color: $color-black;
		font-family: $font-josefin-sans-Bold;
		font-size: 1.8rem;
		line-height: 1.2;
		padding: 1rem 4rem 1rem 2rem;
		position: relative;

		i {
			position: absolute;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}

		@include breakpoint(1023px down) {
			display: block;
		}
	}

	&__items {
		margin-left: auto;
		width: 70%;

		@include breakpoint(1023px down) {
			margin-top: 5rem;
			width: 100%;
		}
	}

	&__item {
		padding: 7.5rem 0;
		position: relative;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&:before {
			content: "";
			background-color: $color-white;
			height: 100%;
			left: -1000%;
			opacity: 0.05;
			position: absolute;
			top: 0;
			width: 2000%;
			z-index: -1;
		}

		&:nth-child(even) {
			&:before {
				background-color: $color-blue;
			}
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__item-row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__item-col {
		margin: 2rem 0;
		padding: 0 1.5rem;

		@include breakpoint(767px down) {
			padding: 0;
		}
	}

	&__item-col-title {
		@extend %o-title-josefin-sans-bold-75;
		color: $color-black;
		line-height: 1;
		text-transform: none;
		margin-bottom: 1.5rem;

		&.yellow {
			color: $color-yellow;
		}

		&.blue {
			color: $color-blue;
		}

		&.green {
			color: $color-green;
		}
	}

	&__item-col-text {
		p {
			@extend %o-text-poppins-regular-18;
		}
	}

	&__item-id {
		position: relative;
		top: -1rem;
	}

	&__item-title {
		@extend %o-title-josefin-sans-bold-36;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 1.5rem;
	}

	&__item-text {
		margin-bottom: 1.5rem;

		p {
			font-size: 1.6rem;
		}
	}

	&__item-list {
		margin-bottom: 1.5rem;

		li {
			margin-bottom: 2rem;
			padding-left: 4.5rem;
			padding-top: 0.4rem;
			position: relative;

			&.hover-info {
				#{$this}__item-list-title {
					display: inline-block;
				}

				#{$this}__item-list-title-box {
					display: flex;
					align-items: center;
					margin-bottom: 0;

					span {
						flex: 0 1 auto;
						width: calc(100% - 2rem);
						padding-right: 1rem;
					}

					svg {
						flex: 0 1 auto;
						width: 2rem;
					}
				}

				#{$this}__item-list-text {
					display: none;
					min-width: calc(50% - 4.5rem);
					padding-top: 1rem;
					position: absolute;
					z-index: 2;

					&:hover {
						display: block !important;
					}

					@include breakpoint(767px down) {
						padding-top: 0.5rem;
						width: calc(100% - 3.5rem) !important;
					}

					p {
						font-size: 1.4rem;
					}
				}

				#{$this}__item-list-text-bridge {
					height: 4.7rem;
					position: absolute;
					right: 1rem;
					top: -3.4rem;
					width: 2rem;

					@include breakpoint(1024px down) {
						display: none;
					}
				}

				#{$this}__item-list-text-box {
					background-color: #fff;
					border: 0.2rem solid rgba(37, 41, 28, 0.2);
					box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.1);
					padding: 1.8rem;
					position: relative;
				}

				#{$this}__item-list-text-arrow {
					display: block;
					background: $color-white;
					border-top: 0.2rem solid rgba(37, 41, 28, 0.2);
					border-right: 0.2rem solid rgba(37, 41, 28, 0.2);
					height: 2rem;
					position: absolute;
					left: 0;
					top: -1.1rem;
					width: 2rem;
					transform: rotate(-45deg);
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__item-list-icon {
		left: 0;
		position: absolute;
		top: 0;
	}

	&__item-list-title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		position: relative;

		span {
			color: $color-black;
			display: inline-block;
			font-family: $font-josefin-sans-Bold;
			font-size: 1.8rem;
			line-height: 1.2;
		}
	}

	&__item-list-text {
		p {
			font-size: 1.6rem;
		}
	}

	&__item-list-text-arrow {
		display: none;
	}
}
