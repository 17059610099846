%o-text-poppins-heavy-14 {
	font-family: $font-poppins-Bold;
	font-size: 1.4rem;
	font-weight: 800;
}

%o-text-poppins-bold-18 {
	font-family: $font-poppins-Bold;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-text-poppins-bold-16 {
	font-family: $font-poppins-Bold;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-text-poppins-bold-14 {
	font-family: $font-poppins-Bold;
	font-size: 1.4rem;
	font-weight: 700;
}

%o-text-poppins-medium-20 {
	font-family: $font-poppins-Medium;
	font-size: 2rem;
	font-weight: 500;
}

%o-text-poppins-semibold-14 {
	font-family: $font-poppins-SemiBold;
	font-size: 1.4rem;
	font-weight: 600;
}

%o-text-poppins-semibold-11 {
	font-family: $font-poppins-SemiBold;
	font-size: 1.1rem;
	font-weight: 600;
}

%o-text-poppins-regular-18 {
	font-family: $font-poppins-Regular;
	font-size: 1.8rem;
	font-weight: 400;
}

%o-text-poppins-regular-16 {
	font-family: $font-poppins-Regular;
	font-size: 1.6rem;
	font-weight: 400;
}

%o-text-poppins-regular-14 {
	font-family: $font-poppins-Regular;
	font-size: 1.4rem;
	font-weight: 400;
}

%o-text-poppins-regular-12 {
	font-family: $font-poppins-Regular;
	font-size: 1.2rem;
	font-weight: 400;
}

%o-text-poppins-regular-10 {
	font-family: $font-poppins-Regular;
	font-size: 1rem;
	font-weight: 400;
}

%o-text-poppins-light-22 {
	font-family: $font-poppins-Light;
	font-size: 2.2rem;
	font-weight: 300;
}

%o-text-poppins-light-20 {
	font-family: $font-poppins-Light;
	font-size: 2rem;
	font-weight: 300;
}

%o-text-poppins-light-18 {
	font-family: $font-poppins-Light;
	font-size: 1.8rem;
	font-weight: 300;
}

%o-text-poppins-light-16 {
	font-family: $font-poppins-Light;
	font-size: 1.6rem;
	font-weight: 300;
}

%o-text-poppins-light-14 {
	font-family: $font-poppins-Light;
	font-size: 1.4rem;
	font-weight: 300;
}

%o-text-poppins-light-13 {
	font-family: $font-poppins-Light;
	font-size: 1.3rem;
	font-weight: 300;
}

%o-text-poppins-light-11 {
	font-family: $font-poppins-Light;
	font-size: 1.1rem;
	font-weight: 300;
}

%o-text-poppins-light-8 {
	font-family: $font-poppins-Light;
	font-size: 0.8rem;
	font-weight: 300;
}

%o-text-poppins-regular-11 {
	font-family: $font-poppins-Regular;
	font-size: 1.1rem;
}

%o-text-poppins-regular-13 {
	font-family: $font-poppins-Light;
	font-size: 1.3rem;
}

%o-text-poppins-semibold-10 {
	font-family: $font-poppins-SemiBold;
	font-size: 1rem;
}

%o-text-poppins-light-14 {
	font-family: $font-poppins-Light;
	font-size: 1.4rem;
}

%o-text-poppins-light-13 {
	font-family: $font-poppins-Light;
	font-size: 1.3rem;
}

%o-text-josefin-sans-bold-38 {
	font-family: $font-josefin-sans-Bold;
	font-size: 3.8rem;
	font-weight: 700;
}

%o-text-josefin-sans-bold-18 {
	font-family: $font-josefin-sans-Bold;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-text-josefin-sans-regular-18 {
	font-family: $font-josefin-sans-Regular;
	font-size: 1.8rem;
	font-weight: 400;
}
