.staff-h {
	$this: &;

	&__select {
		padding-left: 0;
		margin-bottom: 5rem;

		li {
			display: inline-block;
			margin-right: 3rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				border-bottom: 2px solid transparent;
				display: block;
				color: #3c3c3c;
				font-family: $font-poppins-Bold;
				font-size: 2.4rem;
				line-height: 1;
				padding-bottom: 1rem;

				&.active,
				&:hover {
					border-bottom-color: #3c3c3c;
				}

				@include breakpoint(mediumLow down) {
					font-size: 1.8rem;
				}
			}
		}
	}

	&__item {
		border-bottom: 1px solid #acadaa;
		padding-bottom: 3.5rem;
		margin-bottom: 3.5rem;

		&:last-child {
			border-bottom: 0 none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}
