.slider-t {
	$this: &;

	&__swiper {
		padding: 4rem 17rem;

		@include breakpoint(1279px down) {
			padding: 4rem 7rem;
		}

		@include breakpoint(767px down) {
			padding: 4rem 5rem;
		}
	}

	&__swiper-wrapper {
		position: relative;
		z-index: 1;
	}

	&__swiper-slide {
		height: auto;
	}

	&__swiper-imgs {
		width: 100%;
		height: 100%;
		padding-top: 4rem;
		padding-bottom: 4rem;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}

	&__swiper-imgs-container {
		width: 100%;
		height: 100%;
		position: relative;
	}

	&__swiper-imgs-list {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;
	}

	&__swiper-imgs-item {
		$trans: 0.4s ease 0s;

		@extend %background-image;
		width: 15rem;
		height: 100%;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity $trans;

		@include breakpoint(1279px down) {
			background-image: none !important;
			width: 5rem;
		}

		@include breakpoint(767px down) {
			width: 3rem;
		}

		&.position-left {
			left: 0;
		}

		&.position-right {
			right: 0;
		}

		&.imgs-item-active {
			opacity: 1;
		}

		&.transition {
			$trans: 0.4s ease 0s;
			transition: opacity $trans;
		}

		div {
			width: 100%;
			height: 100%;
			opacity: 0.75;
		}
	}

	&__swiper-imgs-white {
		background-color: $color-white;
		width: 15rem;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: 1;

		&.i0 {
			left: 0;
		}

		&.i1 {
			right: 0;
		}

		@include breakpoint(1279px down) {
			width: 5rem;
		}

		@include breakpoint(767px down) {
			width: 3rem;
		}
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		width: 15rem;
		height: 100%;
		top: 0;

		&::after {
			display: none;
		}

		@include breakpoint(1279px down) {
			width: 5rem;
		}

		@include breakpoint(767px down) {
			width: 3rem;

			svg {
				width: 1.5rem;
			}
		}
	}

	&__swiper-button-prev {
		left: 0;
		transform: rotate(180deg);
	}

	&__swiper-button-next {
		right: 0;
	}

	.o-card-slt {
		height: 100%;
	}

	.o-card-slt__img {
		@include breakpoint(767px down) {
			display: none;
		}

		img {
			@include breakpoint(mediumLow down) {
				width: 100%;
			}
		}
	}
}

.slider-pt {
	$this: &;

	&__content {
		@include breakpoint(1023px down) {
			margin-bottom: 2rem;
		}
	}

	&__title {
		@extend %o-title-poppins-bold-24;
		line-height: 1.2;
		margin-bottom: 0;
	}

	&__filter {
		margin-top: 1.5rem;

		ul,
		ol {
			li {
				color: $color-green;
				cursor: pointer;
				font-size: 1.6rem;
				line-height: 1.3;
				margin-bottom: 1.5rem;
				transition: color 0.4s ease;

				&.active {
					font-family: $font-poppins-SemiBold;
				}

				&:hover {
					color: $color-blue;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__text {
		margin-top: 2rem;

		p {
			font-size: 1.6rem;
		}
	}

	&__slider {
		position: relative;
	}

	&__slider-box {
		opacity: 1;
		transition: opacity 0.4s ease;

		&.hidden {
			opacity: 0;
		}
	}

	&__slider-loader {
		opacity: 1;
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
		transition: opacity 0.4s ease;

		&.hidden {
			opacity: 0;
		}
	}

	&__slider-message {
		@extend %o-title-poppins-bold-18;
		line-height: 1.2;
		margin-bottom: 0;
		text-align: center;
		width: 100%;
	}

	&__swiper {
		padding: 0 2rem 1.5rem;
	}

	&__swiper-slide {
		height: auto;
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		height: 100%;
		top: 0;

		&::after {
			display: none;
		}

		@include breakpoint(767px down) {
			svg {
				width: 1.5rem;
			}
		}
	}

	&__swiper-button-prev {
		left: -1rem;
	}

	&__swiper-button-next {
		right: -1rem;
	}

	.o-card-spt {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
	}

	.o-card-spt__img {
		flex: 0 0 auto;
	}

	.o-card-spt__content {
		flex: 1 1 0;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
	}

	.o-card-spt__top {
		flex: 0 1 auto;
	}

	.o-card-spt__cat {
		min-height: 3rem;
	}

	.o-card-spt__text {
		flex: 0 1 auto;
		align-self: flex-end;
		width: 100%;
	}
}
