.post-content-d {
	$this: &;
	$gap: 3rem;

	&__content {
		> * {
			margin-bottom: 4rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-dark-gray-3;
		line-height: 1.2;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		&.font-size-54 {
			@extend %o-title-josefin-sans-bold-54;

			@include breakpoint(767px down) {
				font-size: 2.5rem;
			}
		}
	}

	&__text {
		.button-box {
			margin-top: 3rem;
			margin-bottom: 3rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__form-container {
		background-color: $color-white;
		box-shadow: 0.2rem 0.2rem 2.5rem rgba(0, 0, 0, 0.1);
		padding: 5rem;

		@include breakpoint(1023px down) {
			padding: 4rem;
		}
	}

	&__form-title {
		@extend %o-title-josefin-sans-bold-28;
		line-height: 1;
		margin-bottom: 0;
	}

	&.has-two-columns {
		#{$this}__col {
			width: 50%;

			&:nth-child(1) {
				@include breakpoint(1024px up) {
					padding-right: $gap;
				}
			}

			&:nth-child(2) {
				@include breakpoint(1024px up) {
					padding-left: $gap;
				}
			}

			@include breakpoint(1023px down) {
				width: 100%;
				margin-bottom: 5rem;
			}
		}
	}

	&.resource-ungated {
		#{$this}__col {
			&--content {
				order: 1;

				@include breakpoint(1023px down) {
					order: 2;
				}
			}

			&--media {
				order: 2;

				@include breakpoint(1023px down) {
					order: 1;
				}
			}
		}
	}

	&.resource-gated,
	&.demo {
		#{$this}__col {
			&--content {
				order: 1;
			}

			&--form {
				order: 2;
			}
		}
	}

	&.demo {
		#{$this}__title {
			padding-right: 20%;

			@include breakpoint(1023px down) {
				padding-right: 0;
			}
		}

		#{$this}__text {
			padding-right: 20%;

			@include breakpoint(1023px down) {
				padding-right: 0;
			}
		}

		#{$this}__form {
			position: relative;

			&::before {
				background-color: $color-yellow;
				border-radius: 50%;
				display: block;
				content: "";
				width: 76px;
				height: 76px;
				position: absolute;
				top: -33px;
				left: -33px;
				z-index: 1;
			}
		}

		#{$this}__form-container {
			position: relative;
			z-index: 2;
		}
	}
}
