.o-card-ccd {
	$this: &;

	background-color: $color-white;
	border-radius: 10px;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);
	overflow: hidden;

	&__content {
		padding: 2.5rem;

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__meta {
		margin-bottom: 1rem;
	}

	&__category {
		color: $color-dark-gray-3;
		font-family: $font-josefin-sans-Bold;
		font-size: 1.4rem;
		line-height: 1;
		letter-spacing: 2px;
		text-transform: uppercase;
		opacity: 0.4;
	}

	&__img {
		@extend %background-image;
		padding-top: 63.15%;
		position: relative;

		img {
			width: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-24;
		color: $color-black;
		line-height: 1.5;
		text-transform: none;
		margin-bottom: 1rem;

		a {
			color: inherit;
			font: inherit;
		}
	}

	&__text,
	&__link {
		margin-bottom: 1.5rem;
	}

	&__play {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.o-card-ccdt {
	$this: &;

	&__img {
		@extend .o-card-ccd__img;
		border-radius: 10px;
		overflow: hidden;
	}

	&__title {
		@extend .o-card-ccd__title;
		margin-top: 2rem;
	}

	&__text {
		margin-top: 1rem;
	}
}

.o-card-ccct {
	$this: &;

	&__title {
		@extend %o-title-josefin-sans-bold-75;
		color: $color-black;
		line-height: 1;
		text-transform: none;
		margin-bottom: 1.5rem;

		&.yellow {
			color: $color-yellow;
		}

		&.blue {
			color: $color-blue;
		}

		&.green {
			color: $color-green;
		}
	}

	&__text {
		p {
			@extend %o-text-poppins-regular-18;
		}
	}
}

.o-card-ccf {
	$this: &;

	position: relative;

	&:after {
		content: url("../../assets/images/flow.svg");
		position: absolute;
		right: -4.5rem;
		top: 8rem;
	}

	&__img {
		background: #fff;
		border-radius: 16rem;
		box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.1);
		height: 16rem;
		line-height: 16rem;
		margin: 0 auto 2.5rem;
		width: 16rem;
	}

	&__title {
		@extend %o-title-josefin-sans-bold-24;
		color: $color-black;
		line-height: 1.25;
		margin-bottom: 0;
	}
}

.o-card-cicd {
	$this: &;

	text-align: center;

	&__icon {
		margin-bottom: 1.5rem;
	}

	&__title {
		@extend %o-title-sora-extrabold-24;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 1.5rem;
	}
}

.o-card-t {
	&__num {
		border-radius: 50%;
		border: 1px solid $color-black;
		width: 3rem;
		height: 3rem;
		margin-bottom: 1.5rem;
		position: relative;

		&:before {
			display: block;
			content: attr(title);
			color: $color-black;
			font-family: $font-poppins-Regular;
			font-size: 1.2rem;
			font-weight: 700;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__text {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@extend %o-title-sora-extrabold-35;
			color: $color-black;
			line-height: 1.3;
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 1.5rem;
		}

		p {
			color: $color-black;
			font-size: 1.6rem;
		}
	}
}

.o-card-fc {
	background-color: $color-white;
	box-shadow: 0 0.1rem 1.5rem rgba(0, 0, 0, 0.1);

	&__image {
		@extend %background-image;
		position: relative;

		img {
			@extend %fill-container;
			opacity: 0;
		}
	}

	&__image-fill {
		padding-top: 62.25%;
	}

	&__content {
		padding: 4rem 3.7rem;

		@include breakpoint(mediumLow down) {
			padding: 3rem 2.7rem;
		}
	}

	&__title {
		color: $color-black;
		font-family: $font-josefin-sans-Bold;
		font-size: 2rem;
		line-height: 1.25;
		margin-top: 0;
		margin-bottom: 0;
	}

	&__text {
		margin-top: 1rem;

		p {
			color: $color-black;
		}
	}

	&__link {
		margin-top: 2rem;
		font-weight: 400;
	}

	&__category {
		font-family: $font-poppins-Regular;
		font-weight: 400;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 1.5rem;
		font-size: 1.4rem;
		display: inline-block;
	}
}

.o-card-cbc {
	$this: &;

	@include breakpoint(mediumLow down) {
		border-bottom: 0.1rem solid #78bcc4;
		cursor: pointer;
	}

	&__title {
		@extend %o-title-avenir-heavy-24;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 1.5rem;
		padding-right: 3rem;
		position: relative;

		@include breakpoint(mediumLow down) {
			margin-bottom: 0;
		}
	}

	&__symbol {
		display: none;
		height: 1.5rem;
		margin-top: -0.1rem;
		position: absolute;
		top: 50%;
		right: 0;
		width: 1.5rem;
		transform: translateY(-50%);

		@include breakpoint(mediumLow down) {
			display: block;
		}

		&:before {
			content: url("../images/plus.svg");

			.active & {
				content: url("../images/minus.svg");
			}
		}
	}

	&__text {
		@include breakpoint(mediumLow down) {
			display: none;
			margin-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		p {
			color: $color-black;
		}
	}
}

.o-card-cbp {
	$this: &;

	&__grid {
		background-color: #f7f8f3;
		box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
	}

	&__content {
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		@include breakpoint(mediumLow down) {
			padding-bottom: 1.5rem;
			padding-left: 5.5rem;
			padding-right: 3rem;
		}
	}

	&__title {
		flex: 0 0 auto;
		@extend %o-title-sora-extrabold-35;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		@include breakpoint(mediumLow down) {
			flex: 0 1 auto;
			width: 100%;
		}
	}

	&__text {
		flex: 1 1 0;
		padding-left: 1.5rem;
		padding-right: 20%;

		@include breakpoint(mediumLow down) {
			flex: 0 1 auto;
			padding: 0;
			width: 100%;
		}

		p {
			@extend %o-text-poppins-light-8;
			color: $color-black;
		}
	}

	&__link {
		padding: 0 1.25rem;

		@include breakpoint(1023px down) {
			padding-bottom: 2.5rem;
			text-align: center;
		}

		@include breakpoint(mediumLow down) {
			margin-left: -3rem;
			margin-top: 4.5rem;
			padding: 0;
			width: calc(100% + 6rem) !important;

			a {
				text-align: center;
				width: 100%;
			}
		}
	}
}

.o-card-bg {
	@extend %background-image;
	position: relative;

	img {
		@extend %fill-container;
	}
}

.o-card-img {
	img {
		width: 100%;
	}
}

.o-card-st {
	img {
		width: 100%;
		margin-bottom: 2.5rem;
	}

	&__name {
		@extend %o-title-sora-extrabold-24;
		color: $color-black;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 1.5rem;
		position: relative;
		z-index: 2;
	}

	&__pos {
		p {
			font-family: $font-poppins-Regular;
			line-height: 1;
			text-align: center;
		}
	}

	&__social {
		text-align: center;
	}

	&__social-link {
		svg {
			width: 2rem;
			height: 2rem;
		}
	}
}

.o-card-sh {
	&__content {
		@include breakpoint(768px up) {
			@include auto();
			padding-right: 3rem;
		}
	}

	&__title {
		color: $color-black;
		font-family: $font-josefin-sans-Bold;
		font-size: 2.4rem;
		line-height: 1;
		margin-bottom: 1.5rem;

		@include breakpoint(mediumLow down) {
			font-size: 1.8rem;
		}
	}

	&__text {
		p {
			color: #3c3c3c;
			font-family: $font-poppins-Regular;
			font-size: 2.4rem;
			line-height: 1;
			margin-bottom: 0;

			@include breakpoint(mediumLow down) {
				font-size: 1.8rem;
			}
		}
	}

	&__btn {
		@include breakpoint(768px up) {
			@include shrink();
		}

		@include breakpoint(mediumLow down) {
			margin-top: 3rem;
		}
	}
}

.o-card-b {
	$this: &;
	@extend %background-image;
	padding: 6rem;
	text-align: center;

	@include breakpoint(1024px down) {
		padding: 3rem;
	}

	&.num-1 {
		background-image: url("../images/box-bg-blue.jpg");
	}

	&.num-2 {
		background-image: url("../images/box-bg-red.jpg");
	}

	&.num-3 {
		background-image: url("../images/box-bg-yellow.jpg");
	}

	&.num-4 {
		background-image: url("../images/box-bg-darkgreen.jpg");
	}

	&.logo {
		background-image: url("../images/box-bg-darkgreen.jpg");

		#{$this}__border {
			display: flex;
			flex-wrap: row wrap;
			align-items: center;
			justify-content: center;
		}
	}

	&__border {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 4rem;

		@include breakpoint(1024px down) {
			padding: 3rem 2.5rem;
		}
	}

	&__no {
		border: 4px solid $color-black;
		border-radius: 50%;
		width: 88px;
		height: 88px;
		margin: 0 auto 4rem;
		position: relative;

		@include breakpoint(mediumLow down) {
			width: 65px;
			height: 65px;
			margin: 0 auto 3rem;
		}

		span {
			@extend %o-title-sora-bold-36;
			color: $color-black;
			line-height: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include breakpoint(mediumLow down) {
				font-size: 3.6rem;
			}
		}
	}

	&__text {
		p {
			@extend %o-text-poppins-medium-20;

			@include breakpoint(mediumLow down) {
				font-size: 1.6rem;
			}
		}
	}
}

.o-card-ccl {
	&__img {
		@extend %background-image;
		padding-top: 54.05%;
		position: relative;

		a {
			@extend %fill-container;
			opacity: 0;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__content {
		background-color: #fff;
		padding: 2.5rem;
	}

	&__title {
		@extend %o-title-lato-black-24;
		color: $color-black;
		line-height: 1.2;
	}

	&__text {
		margin-top: 1rem;

		p {
			color: #3c3c3c;
			line-height: 1.5625;
		}
	}
}

.o-card-pcsl {
	background-color: $color-white;
	padding: 3rem 3.5rem;

	&__title {
		@extend %o-title-poppins-bold-32;
		color: $color-dark-gray-3;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__subtitle {
		@extend %o-title-poppins-regular-24;
		color: $color-light-gray-6;
		margin-top: 0.5rem;

		@include breakpoint(767px down) {
			font-size: 2rem;
		}
	}

	&__text {
		margin-top: 1.5rem;

		p {
			font-size: 1.6rem;
		}
	}
}

.o-card-slt {
	box-shadow: 2px 3px 25px rgba(0, 0, 0, 0.15);
	position: relative;

	@include breakpoint(mediumLow down) {
		justify-content: center;
	}

	&__img {
		@extend %background-image;
		width: 24rem;
		position: relative;
		z-index: 1;

		@include breakpoint(1024px up) {
			img {
				opacity: 0;
			}
		}

		@include breakpoint(1023px down) {
			background-image: none !important;
			width: 100%;
		}
	}

	&__content {
		background-color: $color-white;
		padding: 5rem;
		position: relative;
		z-index: 1;

		@include breakpoint(1024px up) {
			@include auto();
		}

		@include breakpoint(1023px down) {
			padding: 3rem 0;
		}
	}

	&__sym {
		margin-bottom: 2.5rem;
	}

	&__text {
		@extend %o-text-poppins-regular-18;
		color: $color-black;
		line-height: 1.5;
		margin-bottom: 2.5rem;
	}

	&__name {
		@extend %o-title-poppins-bold-16;
		line-height: 1.5;
		text-transform: uppercase;
		margin-bottom: 2.5rem;
	}

	&__pos {
		margin-top: 2.5rem;

		p {
			@extend %o-text-poppins-regular-12;
			line-height: 1.5;
		}
	}
}

.o-card-con {
	text-align: center;

	&__title {
		@extend %o-title-josefin-sans-bold-40;
		line-height: 1;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		margin-top: 4rem;

		p {
			color: $color-black;
		}

		.button-box {
			margin-top: 4rem;
		}
	}
}

.o-card-spt {
	$this: &;

	position: relative;

	&__img {
		@extend %background-image;
		height: 18rem;
		position: relative;
		width: 100%;
	}

	&__play {
		left: 3.8rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&__content {
		background-color: #fff;
		box-shadow: 0.2rem 0.2rem 1.5rem rgba(0, 0, 0, 0.1);
		padding: 3.8rem 3.2rem;
	}

	&__cat {
		@extend %o-title-poppins-semibold-10;
		color: $color-dark-gray;
		display: inline-block;
		margin-right: 0.3rem;
		text-transform: uppercase;
		margin-bottom: 1.5rem;
		letter-spacing: 1px;
	}

	&__title {
		@extend %o-title-poppins-bold-18;
		line-height: 1.2;
		margin-bottom: 1.5rem;

		a {
			color: inherit;
			font: inherit;
		}
	}

	&__text {
		p {
			font-size: 1.6rem;
		}

		.button {
			box-sizing: border-box;
			min-width: 0;
			width: 100%;
		}
	}
}

.o-card-wf {
	$this: &;

	&__title {
		@extend %o-title-josefin-sans-bold-54;
		color: $color-dark-gray-3;
		line-height: 1.2;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		margin-top: 1.5rem;

		p {
			@extend %o-text-poppins-light-16;
			margin-bottom: 2rem;
		}

		.checklist {
			ul {
				li {
					font-size: 1.6rem;

					&:before {
						top: 0.3rem;
					}
				}
			}
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.o-card-rsc {
	$this: &;
	display: flex;
	flex-flow: row wrap;

	&__img {
		flex: 0 0 auto;
	}

	&__content {
		flex: 1 1 0;
		padding-left: 3rem;
	}

	&__title {
		@extend %o-title-josefin-sans-bold-16;
		color: $color-dark-gray-3;
		letter-spacing: 0.2rem;
		line-height: 1.2;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	&__position {
		&.max-width-315 {
			max-width: 31.5rem;
		}

		p {
			@extend %o-text-poppins-light-16;
			color: $color-dark-gray-3;
		}
	}

	&__rating {
		margin-top: 1rem;
	}
}

.o-card-rspc {
	$this: &;

	&__title {
		@extend %o-title-josefin-sans-bold-36;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 1.5rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__subtitle {
		@extend %o-title-josefin-sans-bold-28;
		color: $color-blue;
		line-height: 1.2;
		margin-bottom: 1.5rem;

		@include breakpoint(767px down) {
			font-size: 2rem;
		}
	}

	&__pros,
	&__cons {
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		p {
			color: $color-dark-gray-3;
		}
	}

	&__pros-icon {
		flex: 0 0 auto;
	}

	&__pros-text,
	&__cons-text {
		flex: 1 1 0;
		padding-left: 2.5rem;

		p {
			@extend %o-text-poppins-light-20;
			color: $color-dark-gray-3;
			line-height: 1.5;
		}
	}

	&__pros-text {
		margin-bottom: 1.5rem;
	}
}

.o-card-rsa {
	$this: &;

	&__title {
		@extend %o-title-josefin-sans-bold-28;
		color: $color-black;
		cursor: pointer;
		line-height: 1.2;
		margin-bottom: 0;
		padding-right: 3rem;
		position: relative;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		svg {
			position: absolute;
			right: 0;
			top: 50%;
			transform: rotate(0) translateY(-50%);
		}
	}

	&__content {
		display: none;
		padding-top: 1.5rem;

		p {
			font-size: 1.6rem;
		}
	}
}

.o-card-pd {
	$this: &;

	display: flex;
	flex-flow: row wrap;
	border-radius: 1rem;
	box-shadow: 0.2rem 0.2rem 1.5rem rgba(65, 119, 182, 0.25);
	height: 100%;
	overflow: hidden;
	padding: 5.5rem 3.5rem 4.5rem;
	position: relative;

	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__tag {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: center;
		background-color: $color-yellow;
		height: 18rem;
		padding: 1rem;
		position: absolute;
		right: -10rem;
		top: -10rem;
		width: 18rem;
		transform: rotate(45deg);
	}

	&__tag-text {
		@extend %o-text-poppins-bold-14;
		color: $color-dark-gray-3;
		letter-spacing: 0.1rem;
		line-height: 1.2;
		text-align: center;
		text-transform: uppercase;
	}

	&__top {
		flex: 0 1 auto;
		width: 100%;
	}

	&__title {
		@extend %o-title-sora-bold-36;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 0.5rem;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__subtitle {
		@extend %o-title-poppins-regular-14;
		color: $color-light-gray-14;
		line-height: 1.2;
		margin-bottom: 3.5rem;
	}

	&__content {
		margin-bottom: 1.5rem;

		p {
			@extend %o-text-josefin-sans-regular-18;
			text-align: left;

			strong {
				@extend %o-text-josefin-sans-bold-18;
			}
		}
	}

	&__items {
		margin-bottom: 4rem;
		margin-left: 1rem;
	}

	&__item {
		margin-bottom: 2rem;
		padding-left: 1.5rem;
		position: relative;

		&:before {
			background-color: $color-black;
			border-radius: 50%;
			content: "";
			height: 0.4rem;
			left: 0;
			position: absolute;
			top: 0.7rem;
			width: 0.4rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item-text {
		@extend %o-text-poppins-regular-16;
		color: $color-dark-gray-3;
		line-height: 1.2;
		padding-right: 4rem;
		position: relative;

		svg {
			cursor: pointer;
			position: absolute;
			right: 1rem;
			top: -0.3rem;
		}
	}

	&__item-info {
		display: none;
		margin-left: -4.2rem;
		margin-right: -1.8rem;
		padding-top: 1.8rem;
		position: absolute;
		z-index: 2;
	}

	&__item-info-box {
		background-color: $color-white;
		border: 0.2rem solid rgba(37, 41, 28, 0.2);
		box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.1);
		padding: 1.8rem;
		position: relative;

		&:before {
			background: $color-white;
			border-top: 0.2rem solid rgba(37, 41, 28, 0.2);
			border-right: 0.2rem solid rgba(37, 41, 28, 0.2);
			content: "";
			height: 2rem;
			position: absolute;
			right: 2.6rem;
			top: -1.1rem;
			width: 2rem;
			transform: rotate(-45deg);
		}

		p {
			@extend %o-text-poppins-light-14;
			color: $color-dark-gray-3;
		}
	}

	&__link {
		flex: 0 1 auto;
		align-self: flex-end;
		text-align: center;
		width: 100%;
	}
}
