%button {
	$trans: 0.4s ease 0s;

	background-color: $color-yellow;
	display: inline-block;
	color: $color-black;
	cursor: pointer;
	font-family: $font-poppins-Bold;
	font-size: 1.4rem;
	line-height: 1.2;
	letter-spacing: 1px;
	min-width: 17.5rem;
	padding: 1.5rem 2rem;
	text-align: center;
	text-decoration: none !important;
	text-transform: uppercase;
	transition: background-color $trans, color $trans;

	&:hover {
		color: $color-white;
	}
}

%btn-text {
	$trans: 0.4s ease 0s;

	color: $color-black;
	display: inline-block;
	font-family: $font-poppins-Regular;
	font-size: 1.6rem;
	line-height: 1.2;
	text-decoration: none;
	padding-right: 16px;
	position: relative;
	transition: color $trans;

	svg {
		margin-top: -6px;
		position: absolute;
		top: 50%;
		right: 0;
		transition: color $trans;
	}

	&:hover {
		color: $color-blue;

		svg {
			color: $color-blue;
		}
	}
}

%btn-text-cu {
	color: $color-black;
	cursor: pointer;
	display: inline-block;
	font-family: $font-poppins-Bold;
	font-size: 1.6rem;
}

%btn-skyblue-cu {
	@extend %button;
	background-color: $color-skyblue;
	border-color: $color-skyblue;

	&:hover {
		color: $color-skyblue;
	}
}

%btn-play {
	display: inline-block;
}

%link {
	$trans: 0.4s ease;

	color: $color-green;
	display: inline-block;
	font-family: $font-poppins-SemiBold;
	font-size: 1.4rem;
	line-height: 1.2;
	position: relative;
	text-decoration: underline;
	text-transform: uppercase;
	transition: color $trans;

	&:hover {
		color: $color-blue;
	}
}

%link-arrow {
	$trans: 0.4s ease;

	color: $color-black;
	display: inline-block;
	font-family: $font-poppins-SemiBold;
	font-size: 1.4rem;
	line-height: 1.2;
	transition: color $trans, opacity $trans;
	text-decoration: none;
	text-transform: uppercase;
	padding-right: 1.2rem;
	position: relative;

	span {
		text-decoration: underline;
	}

	&::after {
		border-top: 1px solid $color-black;
		border-right: 1px solid $color-black;
		display: block;
		content: "";
		width: 5px;
		height: 5px;
		transform: rotate(45deg);
		margin-top: -3px;
		position: absolute;
		top: 50%;
		right: 0;
		transition: border-color $trans;
	}

	&:hover {
		color: $color-blue;
	}
}
