.reviews-d {
	$this: &;

	&__menu-items {
		padding-right: 3.7rem;

		@include breakpoint(1023px down) {
			padding-right: 0;
		}
	}

	&__menu-item {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		background-color: rgba(255, 255, 255, 0);
		border-radius: 1rem;
		box-shadow: 0.2rem 0.2rem 1.5rem rgba(225, 239, 255, 0);
		margin-bottom: 2rem;
		padding: 3rem 2.5rem;
		position: relative;

		@include breakpoint(767px down) {
			padding: 2.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.active {
			box-shadow: 0.2rem 0.2rem 1.5rem rgba(225, 239, 255, 1);
			background-color: rgba(255, 255, 255, 1);
		}
	}

	&__menu-item-img {
		flex: 0 0 auto;
	}

	&__menu-item-content {
		flex: 1 1 0;
		padding-left: 3rem;
	}

	&__menu-item-name {
		color: $color-dark-gray-3;
		font-family: $font-josefin-sans-Bold;
		font-size: 1.6rem;
		letter-spacing: 0.2rem;
		line-height: 1.2;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	&__menu-item-content {
		p {
			color: $color-dark-gray-3;
			font-size: 1.6rem;
		}
	}

	&__items {
		padding-left: 3.7rem;

		@include breakpoint(1023px down) {
			margin-top: 5rem;
			padding-left: 0;
		}
	}

	&__item {
		@include animation-name(fadeIn);
		@include animation-duration(1s);
		display: none;

		&.active {
			display: block;
		}
	}

	&__item-title {
		@extend %o-title-poppins-bold-22;
		color: $color-dark-gray-3;
		line-height: 1.2;
		margin-bottom: 2rem;
	}

	&__item-text {
		margin-top: 5rem;

		p {
			line-height: 1.4;
		}
	}

	&__item-link {
		margin-top: 5rem;

		.link--black {
			font-family: $font-poppins-Bold;
			font-size: 2rem;
			text-transform: none;
		}
	}
}

.reviews-c {
	$this: &;

	&__row {
		margin-left: -1.75rem;
		width: calc(100% + 3.5rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		padding: 0 1.75rem;

		@include breakpoint(1023px down) {
			margin-bottom: 3.5rem;
		}

		@include breakpoint(767px down) {
			margin-bottom: 3.5rem;
			padding: 0;
		}
	}

	&__col-box {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		background-color: $color-white;
		border-radius: 1rem;
		box-shadow: 0.2rem 0.2rem 1.5rem rgba(65, 119, 182, 0.25);
		height: 100%;
		padding: 5.5rem 3.5rem;
	}

	&__top {
		flex: 0 1 auto;
		align-self: flex-start;
		width: 100%;
	}

	&__img {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		margin-bottom: 3.5rem;
	}

	&__rating {
		margin-bottom: 3.5rem;
	}

	&__text {
		margin-bottom: 5rem;

		p {
			font-size: 1.6rem;
		}
	}

	&__link {
		flex: 0 1 auto;
		align-self: flex-end;

		p {
			font-size: 1.6rem;
		}
	}
}
