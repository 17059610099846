.center-image-column-d {
	$this: &;

	background-color: #f7f8f3;

	&__title {
		@extend %o-title-sora-extrabold-35;
		color: $color-black;
		line-height: 1.2;
		margin-bottom: 7.5rem;
		text-transform: uppercase;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}
	}

	&__col {
		position: relative;

		&.desktop {
			@include breakpoint(1023px down) {
				display: none;
			}
		}

		&.tablet {
			display: none;

			@include breakpoint(1023px down) {
				display: block;
			}
		}

		&.left {
			padding-right: 4.5rem;
		}

		&.right {
			padding-left: 4.5rem;
		}

		&.slider {
			margin-top: 4.5rem;
		}

		.swiper-slide {
			box-sizing: border-box;
			height: auto;
			padding: 0 8.33%;
		}

		.swiper-pagination {
			bottom: auto;
			margin-top: 4.5rem;
			position: relative;
		}

		.swiper-pagination-bullet {
			background: #dedede;
			height: 0.6rem;
			margin: 0 0.25rem;
			opacity: 1;
			width: 0.6rem;
		}

		.swiper-pagination-bullet-active {
			background-color: #4a4844;
		}

		.swiper-button-prev {
			height: 2rem;
			left: 0;

			@include breakpoint(mediumLow down) {
				left: 0.5rem;
			}

			&:after {
				content: url("../images/chevron-left-6-4a4844.svg");
				font-size: 0;
			}
		}

		.swiper-button-next {
			height: 2rem;
			right: 0;

			@include breakpoint(mediumLow down) {
				right: 0.5rem;
			}

			&:after {
				content: url("../images/chevron-right-6-4a4844.svg");
				font-size: 0;
			}
		}
	}

	&__row {
		margin-bottom: 5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
