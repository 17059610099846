.photo-content-s {
	$this: &;

	&.default {
		&.left-content {
			@include breakpoint(large up) {
				#{$this}__content {
					padding-right: 0;
				}

				#{$this}__default {
					padding-left: 2.5%;
					padding-right: 10%;
				}
			}
		}

		&.right-content {
			@include breakpoint(large up) {
				#{$this}__content {
					padding-left: 0;
				}

				#{$this}__default {
					padding-left: 10%;
					padding-right: 2.5%;
				}
			}
		}

		#{$this}__content-container {
			height: 100%;
			position: relative;
		}

		.o-color-bar {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	&.arrow {
		padding-top: 19rem;
		overflow: hidden;

		@include breakpoint(1023px down) {
			padding-top: 5rem;
		}
	}

	&.icon-1 {
		#{$this}__list {
			margin-top: 3rem;
		}
	}

	&.icon-2 {
		#{$this}__list {
			margin-top: 3rem;
		}
	}

	&.image-size-80 {
		#{$this}__img {
			img {
				width: 80%;

				@media only screen and (max-width: 428px) {
					width: 100%;
				}
			}
		}
	}

	&.left-content {
		#{$this}__media {
			@include breakpoint(large up) {
				order: 2;
			}
		}

		#{$this}__content {
			@include breakpoint(large up) {
				padding-right: 0;
				order: 1;
			}
		}
	}

	&.right-content {
		#{$this}__content {
			@include breakpoint(large up) {
				padding-left: 0;
			}
		}
	}

	&.lift-image {
		#{$this}__media {
			@include breakpoint(large up) {
				position: relative;
				top: -10rem;
			}
		}
	}

	&.original-image-size {
		#{$this}__media {
			img {
				width: auto;
			}
		}

		#{$this}__content {
			@include breakpoint(large up) {
				padding-right: 5rem;
			}
		}
	}

	&.auto-content-width {
		#{$this}__media {
			@include breakpoint(large up) {
				@include shrink();
			}
		}

		#{$this}__content {
			@include breakpoint(large up) {
				@include auto();
			}
		}

		&.left-content {
			#{$this}__content {
				@include breakpoint(large up) {
					padding-right: 8.3333333333%;
				}
			}
		}

		&.right-content {
			#{$this}__content {
				@include breakpoint(large up) {
					padding-left: 8.3333333333%;
				}
			}
		}
	}

	&.with-top-background {
		@extend %background-image;
		padding-top: 13rem;

		@include breakpoint(1023px down) {
			padding-top: 5rem;
		}
	}

	&__cell {
		@include breakpoint(small) {
			justify-content: center;
		}

		@include breakpoint(large) {
			justify-content: space-between;
		}
	}

	&__media {
		position: relative;

		@include breakpoint(large up) {
			width: 50%;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 5rem;
			order: 1;
		}
	}

	&__content {
		@include breakpoint(large up) {
			@include auto();
		}

		@include breakpoint(1023px down) {
			order: 2;
		}
	}

	&__arrow {
		position: relative;

		#{$this}__text {
			margin-top: 3rem;
		}
	}

	&__arrow-bg {
		position: absolute;
		top: -172px;
		left: -116px;
	}

	&__default {
		#{$this}__text {
			margin-top: 1.5rem;
		}
	}

	&__green-yellow-diagonal-stripes {
		background-image: url("../images/green-yellow-diagonal-stripes.svg");
		background-size: contain;
		background-repeat: no-repeat;
		height: 27rem;
		width: 34.1rem;
		position: absolute;
		z-index: -1;
		left: -23rem;
		top: calc(50% + 2.5rem);
	}

	&__icon1 {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		#{$this}__icon {
			width: 38px;
			margin-top: 1rem;
			margin-right: 1.5rem;
		}

		#{$this}__text {
			max-width: 37rem;
		}
	}

	&__icon2 {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		#{$this}__icon {
			width: 40px;
			margin-top: -1rem;
			margin-right: 1.5rem;
		}

		#{$this}__title {
			@extend %o-title-josefin-sans-bold-20;
			color: $color-dark-gray-3;
			line-height: 1.5;
			text-transform: uppercase;
		}

		#{$this}__text {
			margin-top: 1rem;
			max-width: 37rem;
		}
	}

	&__img {
		img {
			width: 100%;

			@media screen and (max-width: 1023px) and (min-width: 768px) {
				display: block;
				width: 80%;
				margin-left: auto;
				margin-right: auto;
			}

			@media screen and (max-width: 744px) {
				display: block;
				width: 80%;
				margin-left: auto;
				margin-right: auto;
			}

			@media only screen and (max-width: 428px) {
				width: 100%;
			}
		}

		.o-video-i {
			border-radius: 0.8rem;
			overflow: hidden;
		}
	}

	&__title {
		@extend %o-title-josefin-sans-bold-42;
		color: $color-black;
		line-height: 1.1;
		margin-top: 0;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 2.5rem;
		}

		.stripe-above-title & {
			font-size: 4rem;

			@include breakpoint(767px down) {
				font-size: 2.5rem;
			}
		}

		&.font-size-40 {
			@extend %o-title-josefin-sans-bold-40;

			@include breakpoint(767px down) {
				font-size: 2.5rem;
			}
		}
	}

	&__text {
		h3,
		h4,
		h5,
		h6 {
			@extend %o-title-josefin-sans-bold-24;
			margin-top: 3rem;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		p {
			color: $color-dark-gray-2;
			font-size: 1.8rem;
			line-height: 1.5;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}

			.stripe-above-title & {
				font-size: 2rem;
			}
		}

		ul {
			li {
				color: $color-dark-gray-2;
				font-size: 1.8rem;
				line-height: 1.5;
			}
		}

		.blue-checklist {
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.button-box {
			margin-top: 4rem;
		}
	}

	&__stripe-above-title {
		margin-bottom: 2.4rem;
	}
}
