.background-d {
	$this: &;

	z-index: 1;

	&__img {
		position: absolute;

		&.top-right {
			top: 0;
			right: 0;
		}
	}
}
