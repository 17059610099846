.post-content-r {
	$this: &;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@extend %o-title-josefin-sans-bold-24;
		color: $color-dark-gray-3;
		line-height: 1.2;
		margin-bottom: 1.5rem;
	}

	p {
		@extend %o-text-poppins-light-16;
		color: $color-black;
		margin-bottom: 3rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	ul {
		margin-bottom: 3rem;

		li {
			@extend %o-text-poppins-light-16;
		}
	}
}
