.footer {
	$this: &;
	background-color: $color-blue-3;
	padding-top: 8rem;

	p {
		color: $color-dark-gray-3;
		font-size: 1.4rem;
		font-family: $font-poppins-Light;
		line-height: 1.6;
	}

	&__info {
		order: 1;
		padding-right: 6.5rem;

		@include breakpoint(1023px down) {
			padding-right: 0;
			text-align: center;
		}

		&.no-padding {
			padding-right: 0;
		}
	}

	&__content {
		margin-top: 2rem;
	}

	&__social {
		margin-top: 2rem;

		@include breakpoint(1023px down) {
			margin-top: 0;
			order: 3;
		}

		li {
			display: inline-block;
			line-height: 1;
			margin-bottom: 0;
			margin-right: 1.5rem;

			a {
				color: $color-blue;
				font-size: 2rem;
				transition: opacity 0.4s ease;

				&:hover {
					color: $color-blue;
					opacity: 0.5;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__menu {
		order: 2;
		padding-left: 6.5rem;

		@include breakpoint(1023px down) {
			margin-top: 5rem;
			order: 3;
			padding-left: 0;
		}

		@include breakpoint(767px down) {
			margin-top: 0;
		}

		> ul {
			margin-left: -2.5rem;
			padding-left: 0;
			width: calc(100% + 5rem);

			@include breakpoint(767px down) {
				margin-left: 0;
				width: 100%;
			}

			> li {
				color: $color-light-gray-4;
				display: inline-block;
				font-family: $font-poppins-Regular;
				font-size: 1.2rem;
				letter-spacing: 0.1rem;
				line-height: 1.5;
				padding-left: 2.5rem;
				padding-right: 2.5rem;

				@include breakpoint(large up) {
					width: 33.33%;
				}

				@include breakpoint(1023px down) {
					width: 50%;
					margin-bottom: 5rem;
				}

				@include breakpoint(767px down) {
					text-align: center;
					width: 100%;
					padding: 0;
					order: 1;
				}

				> a,
				> p {
					display: inline-block;
					color: $color-black;
					font-family: $font-josefin-sans-Bold;
					font-size: 1.4rem;
					letter-spacing: 2px;
					text-decoration: none;
					text-transform: uppercase;
				}

				> a {
					opacity: 1;
					transition: opacity 0.4s ease 0s;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}

		&.has-award {
			@include breakpoint(1023px down) {
				order: 2;
				margin-top: 5rem;
			}
		}

		&.menu-hide {
			display: none;
		}
	}

	&__submenu {
		margin-left: 0;
		margin-top: 2rem;
		padding-left: 0;

		@include breakpoint(mediumLow down) {
			margin-top: 1.5rem;
		}

		> li {
			color: $color-dark-gray-3;
			font-family: $font-poppins-Light;
			font-size: 1.4rem;
			line-height: 1.6;
			margin-bottom: 1.5rem;

			@include breakpoint(mediumLow down) {
				line-height: 1.2;
			}

			&:last-child {
				margin-bottom: 0;
			}

			> a {
				display: inline-block;
				color: inherit;
				font: inherit;
				text-decoration: none;

				@include transition(opacity 0.4s ease);

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	&__award {
		order: 3;
		margin-top: 3rem;

		@include breakpoint(1023px down) {
			order: 4;
			text-align: center;
		}

		ul {
			padding-left: 0;

			li {
				display: inline-block;
				margin-bottom: 2rem;
				margin-right: 2rem;

				&:last-child {
					margin-right: 0;
				}

				a {
					display: block;
				}
			}
		}
	}

	&__bottom {
		order: 4;
		border-top: 1px solid $color-blue-2;
		padding-top: 3rem;
		padding-bottom: 3rem;
		margin-top: 5rem;

		@include breakpoint(960px down) {
			text-align: center;
			display: block;
		}

		@include breakpoint(1024px up) {
			margin-top: 11.2rem;
		}

		&.has-award {
			margin-top: 1rem;

			@include breakpoint(1023px down) {
				margin-top: 3rem;
			}
		}
	}

	&__copyright {
		width: 50%;

		@include breakpoint(960px down) {
			width: 100%;
			text-align: center;
		}

		p {
			font-family: $font-poppins-Regular;

			@include breakpoint(mediumLow down) {
				font-size: 1.3rem;
			}
		}
	}

	&__contact {
		text-align: right;
		width: 50%;

		@include breakpoint(960px down) {
			width: 100%;
			text-align: center;
		}

		p {
			font-family: $font-poppins-Regular;

			a {
				color: inherit;
				font: inherit;
				text-decoration: none;
			}

			span {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
			}
		}
	}
}
