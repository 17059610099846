.swiper-template-d {
	padding-bottom: 5rem;
}

.swiper-button-prev-d,
.swiper-button-next-d {
	width: 18px;
	height: 32px;

	&::after {
		font-size: 0;
	}
}

.swiper-pagination-d {
	&.swiper-pagination-bullets {
		bottom: 0;
	}

	.swiper-pagination-bullet {
		background-color: #dedede;
		width: 6px;
		height: 6px;
		margin: 0 3px;
		opacity: 1;

		&.swiper-pagination-bullet-active {
			background-color: #4a4844;
		}
	}
}

.swiper-button-prev-6-4a4844 {
	&::after {
		content: url("../images/chevron-left-6-4a4844.svg");
	}
}

.swiper-button-next-6-4a4844 {
	&::after {
		content: url("../images/chevron-right-6-4a4844.svg");
	}
}

.swiper-button-prev-9-3c3c3c {
	&::after {
		content: url("../images/chevron-left-9-3c3c3c.svg");
		font-size: 20px;
		width: 11px;
		height: 20px;
	}
}

.swiper-button-next-9-3c3c3c {
	&::after {
		content: url("../images/chevron-right-9-3c3c3c.svg");
		font-size: 20px;
		width: 11px;
		height: 20px;
	}
}

.swiper-button-prev-11-3c3c3c {
	&::after {
		content: url("../images/chevron-left-11-3c3c3c.svg");
		font-size: 20px;
		width: 11px;
		height: 20px;
	}
}

.swiper-button-next-11-3c3c3c {
	&::after {
		content: url("../images/chevron-right-11-3c3c3c.svg");
		font-size: 20px;
		width: 11px;
		height: 20px;
	}
}
