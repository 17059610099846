.o-video-i {
	$this: &;

	position: relative;

	&.active {
		#{$this}__overlay {
			pointer-events: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	iframe,
	video {
		@extend %fill-container;
		z-index: 2;
	}

	&__img {
		padding-top: 56.25%;
		opacity: 0;
		position: relative;
		z-index: 1;

		img {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__overlay {
		$trans: 0.4s ease 0.4s;

		@extend %background-image;
		@extend %fill-container;
		cursor: pointer;
		visibility: visible;
		opacity: 1;
		z-index: 3;
		transition: visibility $trans, opacity $trans;

		.btn-play {
			@extend %fill-container;
			pointer-events: none;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				@include breakpoint(mediumLow down) {
					height: 70%;
				}
			}
		}
	}

	&__overlay--no-video {
		cursor: auto;
	}

	&__box {
		@extend %fill-container;
		z-index: 2;
	}
}
